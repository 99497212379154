import React from "react";
import ReactTypingEffect from "react-typing-effect";
import "./blogCards.css";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import { useEffect } from "react";

const Eco = () => {
  useEffect(() => {
    document.title = "Eco Materials | Lounge Luxurio";
  }, []);
  return (
    <div>
      <Navbar />
      <div className=" container-fluid text-center ludwig1" id="name">
        Eco Materials
      </div>
      <div className=" container-fluid text-center ludwig2" id="name">
        <div className="row">
          <div className="col-12 text-center">
            <ReactTypingEffect
              text={[
                `Being green is more than just buying 'eco'. It is an unshakable commitment to a sustainable lifestyle.`,
              ]}
              typingDelay="100"
              eraseDelay="5000000000"
              cursor=" "
              speed="100"
            ></ReactTypingEffect>
          </div>
        </div>
      </div>
      <div
        className=" container-fluid text-center ludwig"
        style={{ color: "yellowgreen" }}
        id="name"
      >
        <div
          className="row"
          style={{ textAlign: "center", marginTop: "-15px" }}
        >
          <div className="col-12">
            <ReactTypingEffect
              text={[`~Jennifer Nini`]}
              eraseDelay="5000000000"
              cursor=" "
              typingDelay="11600"
            ></ReactTypingEffect>
            <br />
          </div>
        </div>
      </div>
      <div className="container" style={{ marginTop: "100px" }}>
        <div className="row">
          <div className="col-12 text-center">
            <h2
              style={{
                borderBottom: " 1px solid yellowgreen",
                paddingBottom: "7px",
                textTransform: "uppercase",
              }}
            >
              Seagrass
            </h2>
          </div>

          <div className="col-md-10 col-sm-12 offset-md-1 text-center">
            <img
              src={require("./images/eco/seagrass.jpg")}
              className="banner"
            />
          </div>

          <div className="col-12 text-center details">
            <p>
              Consider my word; it’s a myth that Sea-grass supplements a
              beach-themed house. Besides being sustainable, they add an
              exclusive glamour to your interiors. Upon meting out, they craft a
              fibre, which in turn can be used for rugs or knit into cloth, to
              adorn furniture frames. If aptly cared for, home furnishings made
              out of sea-grass last for an indefinite period. Obtainable in
              wide-ranging colours and patterns, they are always eye-catching
              add-ons to a space. They blend beautifully with the décor of your
              home. All you have to see to for their maintenance is a frequent
              wipe with a moist cloth to avoid surfacing of dust. Sea-grass, as
              an eco-material offers accessibility, sustainability, durability
              and allure.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-12 text-center" style={{ marginTop: "60px" }}>
            <h2
              style={{
                borderBottom: " 1px solid yellowgreen",
                paddingBottom: "7px",
                textTransform: "uppercase",
              }}
            >
              Insulating Concrete Foams
            </h2>
          </div>

          <div className="col-md-10 col-sm-12 offset-md-1 text-center">
            <img
              src={require("./images/eco/concrete.jpg")}
              className="banner"
            />
          </div>

          <div className="col-12 text-center details">
            <p>
              Pronounced by The Portland Cement Association as a{" "}
              <i>
                {" "}
                ‘cast-in-place concrete walls that are sandwiched between two
                layers of insulation material ’
              </i>
              , Concrete-foams have widely been used as green construction
              materials for an inordinate length of time. They provide aid in
              the insulation of both houses and buildings. Energy-efficiency
              being a dynamic feature, this equipment is used in building blocks
              and self-supporting walls.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-12 text-center" style={{ marginTop: "60px" }}>
            <h2
              style={{
                borderBottom: " 1px solid yellowgreen",
                paddingBottom: "7px",
                textTransform: "uppercase",
              }}
            >
              Low Emissivity Glass
            </h2>
          </div>

          <div className="col-md-10 col-sm-12 offset-md-1 text-center">
            <img
              src={require("./images/eco/low-e-glass.jpg")}
              className="banner"
            />
          </div>

          <div className="col-12 text-center details">
            <p>
              With a view of retaining the heat within the house in wintertime
              while at the same time, blocking the heat from entering inside in
              the course of sunshine, this variety of windows is coated with
              metallic oxide and holds a low emissivity ratio. These have
              eclectic usage as outer storm-windows in dwellings where
              dual-panel windows are not mounted. Although costlier than the
              regular glass storm-window, this assortment of windows cuts down
              the heating cost by around 20%. The technology is available in
              both hard and soft coatings. The soft coats go between the layers
              of glass while the hard coats go outside. A Low-emissivity window
              first and foremost reduces heat flow through the glass.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-12 text-center" style={{ marginTop: "60px" }}>
            <h2
              style={{
                borderBottom: " 1px solid yellowgreen",
                paddingBottom: "7px",
                textTransform: "uppercase",
              }}
            >
              Solar Roof Shingles
            </h2>
          </div>

          <div className="col-md-10 col-sm-12 offset-md-1 text-center">
            <img
              src={require("./images/eco/silver-and-black-solar-panels-on-snow-covered-ground-159160.jpg")}
              className="banner"
            />
          </div>

          <div className="col-12 text-center details">
            <p>
              Being sized just as much as the conservative solar-panels, the
              operation of Solar roof shingles is more or less the same. They
              can be affixed directly to the tiling fabric often referred to as
              tar cloth. The outmoded PV shingle designs needed vented plywood
              decking for heat indulgence and pre-laid cabling prior to the
              placement of shingles. The new-fangled versions have, however done
              away with these necessities. In contrast to the old-fashioned
              panels, the connection of these shingles is not as long a process,
              thus making it lower-per watt to set up. With an automated setup
              of the circuits, the electrical structure is almost wire-less.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-12 text-center" style={{ marginTop: "60px" }}>
            <h2
              style={{
                borderBottom: " 1px solid yellowgreen",
                paddingBottom: "7px",
                textTransform: "uppercase",
              }}
            >
              Wool Bricks
            </h2>
          </div>

          <div className="col-md-10 col-sm-12 offset-md-1 text-center">
            <img src={require("./images/eco/wool.jpg")} className="banner" />
          </div>

          <div className="col-12 text-center details">
            <p>
              On being reinforced with wool, a more sustainable and innocuous
              compound was attained, which could be shaped using ample of local
              resources. This would mechanically toughen the brick. The wool
              fibres were added to the clay material used in the bricks, using
              alginate conglomerate, a natural polymer found in the cell walls
              of seaweed. The compound was found to be almost 37% sturdier than
              the typically-used bricks. These fibres advance the power of
              compacted bricks, ease the development of cracks and malformations
              owing to contraction, reduce the drying time and upsurge the
              bricks’ resistance to flexion.
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Eco;
