import React from "react";
import ReactTypingEffect from "react-typing-effect";
import "./blogCards.css";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import { useEffect } from "react";

const HiTech = () => {
  useEffect(() => {
    document.title = "Hi-Tech Furniture Design | Lounge Luxurio";
  }, []);
  return (
    <div>
      <Navbar />
      <div className=" container-fluid text-center ludwig1" id="name">
        Hi-Tech Furniture Design
      </div>
      <div className=" container-fluid text-center ludwig2" id="name">
        <div className="row">
          <div className="col-12 text-center">
            <ReactTypingEffect
              text={[`Furniture is meant to be used and enjoyed.`]}
              typingDelay="100"
              eraseDelay="5000000000"
              cursor=" "
              speed="100"
            ></ReactTypingEffect>
          </div>
        </div>
      </div>
      <div
        className=" container-fluid text-center ludwig"
        style={{ color: "yellowgreen" }}
        id="name"
      >
        <div
          className="row"
          style={{ textAlign: "center", marginTop: "-15px" }}
        >
          <div className="col-12">
            <ReactTypingEffect
              text={[`~Natalie Morales`]}
              eraseDelay="5000000000"
              cursor=" "
              typingDelay="5000"
            ></ReactTypingEffect>
            <br />
          </div>
        </div>
      </div>
      <div className="container" style={{ marginTop: "100px" }}>
        <div className="row">
          <div className="col-12 text-center">
            <h2
              style={{
                borderBottom: " 1px solid yellowgreen",
                paddingBottom: "7px",
                textTransform: "uppercase",
              }}
            >
              The Staircase as Storage Space miracle
            </h2>
          </div>

          <div className="col-md-10 col-sm-12 offset-md-1 text-center">
            <img
              src={require("./images/hitech/under-stair-storage-ideas.jpg")}
              className="banner"
            />
          </div>

          <div className="col-12 text-center details">
            <p>
              As observed, staircases are often constructed such that they leave
              a lot of space vacant i.e. complete wastage of area. You will be
              interested to know that there is a great potential for this space
              to be utilized economically as storage. These storage spaces are
              built beneath the stairs as differently sized cabinets which can
              be accessed through the ‘one-touch open system’.
              <br />
              <br />
              <span style={{ color: "yellowgreen" }}>Applications :</span>{" "}
              <br />
              <span style={{ color: "yellowgreen" }}> &#8226; </span>Shoe-rack
              <br />
              <span style={{ color: "yellowgreen" }}>&#8226; </span>Clothing
              Wardrobe
              <br />
              <span style={{ color: "yellowgreen" }}>&#8226; </span>Secret
              Documents <br />
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-12 text-center" style={{ marginTop: "60px" }}>
            <h2
              style={{
                borderBottom: " 1px solid yellowgreen",
                paddingBottom: "7px",
                textTransform: "uppercase",
              }}
            >
              The Disguised Home Office
            </h2>
          </div>

          <div className="col-md-10 col-sm-12 offset-md-1 text-center">
            <img
              src={require("./images/hitech/office.jpg")}
              className="banner"
            />
          </div>

          <div className="col-12 text-center details">
            <p>
              Often, workplace situated at home may disturb the comfort and aura
              of the living space. An effective way out is an office condensed
              in nature, which can disappear in the evening by being transformed
              into a cabinet. The same can be made into a working office yet
              again in the morning.
              <br /> <br />
              <span style={{ color: "yellowgreen" }}>
                {" "}
                Features :
              </span> <br />{" "}
              <span style={{ color: "yellowgreen" }}>&#8226; </span> The working
              surface can be brought to the desired height using electric
              motors.
              <br /> <span style={{ color: "yellowgreen" }}>&#8226; </span>
              The storage cabinet can be opened by the ‘one-touch open system’.
              <br /> <span style={{ color: "yellowgreen" }}>&#8226; </span>{" "}
              Silent hinges and negligible noise generated in conversion of the
              room into a workplace.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-12 text-center" style={{ marginTop: "60px" }}>
            <h2
              style={{
                borderBottom: " 1px solid yellowgreen",
                paddingBottom: "7px",
                textTransform: "uppercase",
              }}
            >
              Digitalized Restrocafe Table-tops
            </h2>
          </div>

          <div className="col-md-10 col-sm-12 offset-md-1 text-center">
            <img
              src={require("./images/hitech/smart table.jpg")}
              className="banner"
            />
          </div>

          <div className="col-12 text-center details">
            <p>
              These are the digital touchscreen table-tops that can be used in
              restaurants, cafes, bars, clubs and many other entertainment
              zones. Their utility involves browsing as well as placing the
              orders straight from the screen. It works on ‘One-Touch Order
              &amp; Pay’ technology. It serves as a luxurious cum user-friendly
              furniture.
              <br /> <br />
              <span style={{ color: "yellowgreen" }}>
                {" "}
                Features :
              </span> <br />{" "}
              <span style={{ color: "yellowgreen" }}>&#8226; </span> Easy
              Browsing
              <br /> <span style={{ color: "yellowgreen" }}>&#8226; </span>Sit
              and Order
              <br /> <span style={{ color: "yellowgreen" }}>&#8226; </span>
              Luxurious Appearance
              <br /> <span style={{ color: "yellowgreen" }}>&#8226; </span>{" "}
              ‘One-Touch Order &amp; Pay’
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-12 text-center" style={{ marginTop: "60px" }}>
            <h2
              style={{
                borderBottom: " 1px solid yellowgreen",
                paddingBottom: "7px",
                textTransform: "uppercase",
              }}
            >
              Modern Sofa cum Bed
            </h2>
          </div>

          <div className="col-md-10 col-sm-12 offset-md-1 text-center">
            <img
              src={require("./images/hitech/building-chair-comfort-comfortable-276528 (1).jpg")}
              className="banner"
            />
          </div>

          <div className="col-12 text-center details">
            <p>
              With a two-in-one feature, this furniture style is one of the
              ‘state-of-the-art’ trends in the world of modern interior design.
              It serves as a sofa during the hours of daylight and can be
              altered into a comfortable bed at nightfall for relaxation. It is
              portable and convenient-to-use equipment.
              <br /> <br />
              <span style={{ color: "yellowgreen" }}>
                {" "}
                Features :
              </span> <br />{" "}
              <span style={{ color: "yellowgreen" }}>&#8226; </span> Elegant and
              aesthetic appearance
              <br /> <span style={{ color: "yellowgreen" }}>&#8226; </span>
              Comfort at its crowning
              <br /> <span style={{ color: "yellowgreen" }}>&#8226; </span>{" "}
              Moveable
              <br /> <span style={{ color: "yellowgreen" }}>&#8226; </span>{" "}
              Hybrid
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default HiTech;
