import React, { useState, useEffect, Fragment } from "react";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";

const Service = () => {
  const [values, setvalues] = useState({
    name: "",
    phone: "",
    email: "",
    error: "",
    success: false,
    service: "",
    description: "",
  });

  useEffect(() => {
    document.title = "Services Queries | Lounge Luxurio";
  }, []);

  const { name, phone, email, error, success, service, description } = values;
  const handleChange = (name) => (event) => {
    return setvalues({
      ...values,
      error: false,
      success: false,
      [name]: event.target.value,
    });
  };
  const onSuccess = () => (
    <div className="col-md-6 offset-sm-3 text-center">
      <div
        className="alert alert-success"
        style={{ display: success ? "" : "none" }}
      >
        Your query has been noted! We'll get back to you soon.
      </div>
    </div>
  );

  const onSubmit = (event) => {
    event.preventDefault();
    console.log("Running");
    let st = "Enter Your";

    if (name.trim() === "") {
      st += " Name,";
    }
    if (email.trim() === "") {
      st += " Email Address,";
    }
    if (service.trim() === "") {
      st += " Required Service,";
    }

    if (service === "Other" && description.trim() === "") {
      st += " Requirement's Description,";
    }

    if (st.trim() !== "Enter Your") {
      setvalues({ ...values, error: st.slice(0, -1), success: false });
      return;
    }

    function ValidateEmail(mail) {
      if (
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
          mail
        )
      ) {
        return true;
      }
      return false;
    }

    if (!ValidateEmail(email)) {
      setvalues({
        ...values,
        error: "Enter Valid Email Address",
        success: false,
      });
      return;
    }

    const scriptURL =
      "https://script.google.com/macros/s/AKfycbwA1rnvQz37rGdzFnb_2MnMv_sYU525mNdEs71HQ-LO-bL2kAQ/exec";
    const form = document.forms["service"];
    var joinForm = new FormData(form);
    fetch(scriptURL, { method: "POST", body: joinForm })
      .then((response) => setvalues({ ...values, success: true }))
      .catch((error) => console.error("Error!", error.message));
  };

  const onError = () => (
    <div className="col-md-6 offset-sm-3 text-center mt-5">
      <div
        className="alert alert-danger"
        style={{ display: error ? "" : "none" }}
      >
        {error}
      </div>
    </div>
  );
  const signupForm = () => {
    return (
      <div className="container-fluid mt-5">
        <div className="row">
          <div
            className="col-md-6 offset-sm-3 text-left p-2"
            style={{ backgroundColor: "black", color: "yellowgreen" }}
          >
            <form className="px-4" name="service">
              <div className="form-group mt-3">
                <label>
                  Name <span className="text-danger">*</span>
                </label>
                <input
                  onChange={handleChange("name")}
                  className="form-control"
                  type="text"
                  value={name}
                  autoCapitalize="words"
                  autoFocus
                  name="Name"
                />
              </div>

              <div className="form-group">
                <label>
                  Email <span className="text-danger">*</span>
                </label>
                <input
                  onChange={handleChange("email")}
                  className="form-control"
                  type="email"
                  name="Email"
                  value={email}
                />
              </div>
              <div className="form-group">
                <label>Phone No.</label>
                <input
                  onChange={handleChange("phone")}
                  className="form-control"
                  type="phone"
                  value={phone}
                  name="Phone"
                />
              </div>
              <div className="form-group">
                <label>Service Required</label>
                <span className="text-danger">*</span>
                <select
                  className="form-control"
                  name="Service"
                  value={service}
                  onChange={handleChange("service")}
                >
                  <option value="">Select Service</option>
                  <option value="2-D Design Planning & Consultation">
                    2-D Design Planning & Consultation
                  </option>
                  <option value="3-D Visualisation & Consultation">
                    3-D Visualisation & Consultation
                  </option>
                  <option value="Structural Analysis">
                    Structural Analysis
                  </option>
                  <option value="Estimation">Estimation</option>
                  <option value="Interior Decoration & Design">
                    Interior Decoration & Design
                  </option>
                  <option value="Site Visits">Site Visits</option>
                  <option value="Vastu Tips">Vastu Tips</option>
                  <option value="Other">Other</option>
                </select>
              </div>

              <div className="form-group">
                <label>
                  Description{" "}
                  <span
                    className="text-danger"
                    style={{ display: service === "Other" ? "" : "none" }}
                  >
                    *
                  </span>
                </label>
                <textarea
                  onChange={handleChange("description")}
                  className="form-control"
                  type="text"
                  value={description}
                  name="Description"
                />
              </div>

              <div style={{ textAlign: "right" }}>
                <button
                  onClick={onSubmit}
                  className="btn"
                  type="submit"
                  style={{ backgroundColor: "yellowgreen" }}
                >
                  Submit
                </button>
              </div>

              <br />
            </form>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      <Navbar />
      <div className="container">
        <div className="row text-center">
          <div className="col-12 mt-5">
            <h4 className="font-weight-bold" id="join-us">
              Fill The Form To{" "}
              <span style={{ color: "yellowgreen" }}>Avail </span>Our{" "}
              <span style={{ color: "yellowgreen" }}>Services</span>
            </h4>
          </div>
        </div>
      </div>
      {onError()}
      {onSuccess()}
      {signupForm()}
      <Footer />
    </div>
  );
};

export default Service;
