import React from "react";
import ReactTypingEffect from "react-typing-effect";
import "./blogCards.css";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import { useEffect } from "react";

const Interior = () => {
  useEffect(() => {
    document.title = "Interior Decoration | Lounge Luxurio";
  }, []);
  return (
    <div>
      <Navbar />
      <div className=" container-fluid text-center ludwig1" id="name">
        Interior Decoration
      </div>
      <div className=" container-fluid text-center ludwig2" id="name">
        <div className="row">
          <div className="col-12 text-center">
            <ReactTypingEffect
              text={[
                `Your home should tell the story of who you are, and be a collection of what you love.`,
              ]}
              typingDelay="100"
              eraseDelay="5000000000"
              cursor=" "
              speed="100"
            ></ReactTypingEffect>
          </div>
        </div>
      </div>
      <div
        className=" container-fluid text-center ludwig"
        style={{ color: "yellowgreen" }}
        id="name"
      >
        <div
          className="row"
          style={{ textAlign: "center", marginTop: "-15px" }}
        >
          <div className="col-12">
            <ReactTypingEffect
              text={[`~Nate Berkus`]}
              eraseDelay="5000000000"
              cursor=" "
              typingDelay="9900"
            ></ReactTypingEffect>
            <br />
          </div>
        </div>
      </div>
      <div className="container" style={{ marginTop: "100px" }}>
        <div className="row">
          <div className="col-12 text-center">
            <h2
              style={{
                borderBottom: " 1px solid yellowgreen",
                paddingBottom: "7px",
                textTransform: "uppercase",
              }}
            >
              Lighting
            </h2>
          </div>

          <div className="col-md-10 col-sm-12 offset-md-1 text-center">
            <img
              src={require("./images/interior/gray-dining-table-under-pendant-lamps-3356416.jpg")}
              className="banner"
            />
          </div>

          <div className="col-12 text-center details">
            <p>
              Lighting marks one of the pre-eminent facets of interior décor, as
              it holds the ability of modifying the complete outlook of any
              space. A dull room can be rehabilitated into one ambient and
              eye-catching. Hence, it becomes very important to decide on the
              apt lighting system.
              <br />
              <br />
              <span style={{ color: "yellowgreen" }}>
                Types of Interior Lighting : <br />
              </span>
              <span style={{ color: "yellowgreen" }}> &#8226; </span>General
              Lighting <br />
              <span style={{ color: "yellowgreen" }}>&#8226; </span>Ambient
              Lighting
              <br />
              <span style={{ color: "yellowgreen" }}>&#8226; </span>Task
              Lighting <br />
              <span style={{ color: "yellowgreen" }}>&#8226; </span>Mood
              Lighting <br />
              <span style={{ color: "yellowgreen" }}>&#8226; </span>Accent
              Lighting
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-12 text-center" style={{ marginTop: "60px" }}>
            <h2
              style={{
                borderBottom: " 1px solid yellowgreen",
                paddingBottom: "7px",
                textTransform: "uppercase",
              }}
            >
              Flooring
            </h2>
          </div>

          <div className="col-md-10 col-sm-12 offset-md-1 text-center">
            <img
              src={require("./images/interior/macro-shot-of-wooden-planks-326333.jpg")}
              className="banner"
            />
          </div>

          <div className="col-12 text-center details">
            <p>
              The style of flooring to be used in one’s house/workplace is a
              very vital decision. It influences the very feel and aura of your
              space. <br /> <br />{" "}
              <span style={{ color: "yellowgreen" }}>
                Types of Interior Flooring :
              </span>{" "}
              <br /> <span style={{ color: "yellowgreen" }}>&#8226; </span>{" "}
              Hardwood Flooring
              <br /> <span style={{ color: "yellowgreen" }}>&#8226; </span>
              Engineered-wood Floors
              <br /> <span style={{ color: "yellowgreen" }}>&#8226; </span>{" "}
              Ceramic Tiles
              <br /> <span style={{ color: "yellowgreen" }}>&#8226; </span>{" "}
              Bamboo Flooring
              <br /> <span style={{ color: "yellowgreen" }}>&#8226; </span>
              Travertine Flooring
              <br /> <span style={{ color: "yellowgreen" }}>&#8226; </span>{" "}
              Laminate
              <br /> <span style={{ color: "yellowgreen" }}>&#8226; </span>{" "}
              Vinyl
              <br /> <span style={{ color: "yellowgreen" }}>&#8226; </span>{" "}
              Linoleum
              <br /> <span style={{ color: "yellowgreen" }}>&#8226; </span>{" "}
              Marble
              <br /> <span style={{ color: "yellowgreen" }}>&#8226; </span>
              Carpet
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Interior;
