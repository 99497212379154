import React from "react";
import { useEffect } from "react";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import "./home.css";
import { photosIdsMap, thumbnailLink } from "../photos";
import ReactTypingEffect from "react-typing-effect";
const Projects = () => {
  useEffect(() => {
    document.title = "Projects | Lounge Luxurio";
  });

  return (
    <>
      <Navbar />
      <div className="container text-center">
        <div className="row">
          <div className="col-12 mt-5 mb-3">
            <h1 className="font-weight-bold">
              <span style={{ color: "yellowgreen" }}>Projects</span>
            </h1>
          </div>
        </div>
        <div className="mb-5">
          <ReactTypingEffect
            text={[`100+ projects and counting`]}
            typingDelay="100"
            eraseDelay="5000000000"
            cursor=" "
            speed="100"
          ></ReactTypingEffect>
        </div>
        <div className="row mt-5 pt-4 justify-content-center">
          {Object.entries(photosIdsMap).map(([project, photos]) => (
            <div className="col-12 col-xl-4 col-md-6 mb-5 pb-3 speaker text-center">
              <a
                href={`/projects/${project.toLowerCase()}`}
                style={{ textDecoration: "none" }}
              >
                <div className="speakers">
                  <div className="speaker-img">
                    <img
                      src={thumbnailLink(photos[0])}
                      alt={project}
                      style={{ height: 240, objectFit: "cover" }}
                    />
                  </div>
                  <div className="speaker-details">
                    <div className="speaker-name">
                      <h4>{project.split("-").join(" ")}</h4>
                    </div>
                  </div>
                </div>
              </a>
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Projects;
