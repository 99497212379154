import React from "react";
import "./footer.css";
export default function Footer() {
  return (
    <footer id="contact">
      <div className="pt-2">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 text-center">Contact Us</div>
            <div className="col-12 text-center text-white pb-2 mt-3 ">
              {" "}
              <a
                href="mailto:loungeluxurio@gmail.com"
                target="_blank"
                rel="noopener noreferrer"
                className="text-white"
              >
                <img
                  src="https://img.icons8.com/ios/200/ffffff/composing-mail.png"
                  className="mr-1"
                  style={{ maxHeight: "32px", maxWidth: "32px" }}
                  alt="mail"
                />{" "}
                {"loungeluxurio@gmail.com"}
              </a>
            </div>
            <div className="col-12 text-center text-white pb-3">
              {" "}
              <a
                href="tel:+919621464646"
                target="_blank"
                rel="noopener noreferrer"
                className="text-white"
              >
                <img
                  src="https://img.icons8.com/ios/200/ffffff/phone.png"
                  className="mr-1"
                  style={{ maxHeight: "32px", maxWidth: "32px" }}
                  alt="phone"
                />{" "}
                {"+91-9621-464-646"}
              </a>
            </div>
            <div className="col-12 text-center text-white pb-3">
              {" "}
              <a
                href="https://www.instagram.com/loungeluxurio/"
                target="_blank"
                rel="noopener noreferrer"
                className="text-white"
              >
                <img
                  src="https://img.icons8.com/ios/150/ffffff/instagram-new.png"
                  className="mr-1"
                  style={{ maxHeight: "32px", maxWidth: "32px" }}
                  alt="instagram"
                />{" "}
                @loungeluxurio
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="footer text-muted mr-2">
        <p style={{ fontSize: 11 }}>
          © {new Date().getFullYear()}, Lounge Luxurio Pvt. Ltd. All Rights
          Reserved{" "}
        </p>
      </div>
    </footer>
  );
}
