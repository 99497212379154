import React, { useState, useEffect, Fragment } from "react";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";

const Join = () => {
  const [inputFields, setInputFields] = useState([""]);
  const [values, setvalues] = useState({
    name: "",
    phone: "",
    email: "",
    error: "",
    success: false,
  });

  useEffect(() => {
    document.title = "Join Us | Lounge Luxurio";
  }, []);

  const { name, phone, email, error, success } = values;

  const handleChange = (name) => (event) => {
    return setvalues({
      ...values,
      error: false,
      success: false,
      [name]: event.target.value,
    });
  };
  const onSuccess = () => (
    <div className="col-md-6 offset-sm-3 text-center">
      <div
        className="alert alert-success"
        style={{ display: success ? "" : "none" }}
      >
        Thanks for joining us!
      </div>
    </div>
  );

  const onError = () => (
    <div className="col-md-6 offset-sm-3 text-center mt-5">
      <div
        className="alert alert-danger"
        style={{ display: error ? "" : "none" }}
      >
        {error}
      </div>
    </div>
  );

  const handleInputChange = (index, event) => {
    const values = [...inputFields];
    values[index] = event.target.value;
    setInputFields(values);
  };

  const handleAddFields = () => {
    const values = [...inputFields];
    values.push("");
    setInputFields(values);
  };

  const handleRemoveFields = (index) => {
    const values = [...inputFields];
    values.splice(index, 1);
    setInputFields(values);
  };

  const onSubmit = (event) => {
    event.preventDefault();
    let st = "Enter Your";

    if (name.trim() === "") {
      st += " Name,";
    }
    if (email.trim() === "") {
      st += " Email Address,";
    }
    if (inputFields[0].trim() === "") {
      st += " Skill(s),";
    }

    if (st.trim() !== "Enter Your") {
      setvalues({ ...values, error: st.slice(0, -1), success: false });
      return;
    }

    function ValidateEmail(mail) {
      if (
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
          mail
        )
      ) {
        return true;
      }
      return false;
    }

    if (!ValidateEmail(email)) {
      setvalues({
        ...values,
        error: "Enter Valid Email Address",
        success: false,
      });
      return;
    }

    var flag = false;
    inputFields.forEach((inp) => {
      if (inp.trim() === "") {
        flag = true;
        setvalues({ ...values, error: "Empty skill(s) field", success: false });
        // eslint-disable-next-line
        return;
      }
    });

    if (flag) {
      return;
    }

    const scriptURL =
      "https://script.google.com/macros/s/AKfycbz78viSpYqcu8Q2sVb-wda1GqZ0LDbTa1V-Y7Dldc5LLTZub50/exec";
    const form = document.forms["sign"];
    var joinForm = new FormData(form);
    joinForm.append("Skills", JSON.stringify(inputFields));
    fetch(scriptURL, { method: "POST", body: joinForm })
      .then(() => setvalues({ ...values, success: true }))
      .catch((error) => console.error("Error!", error.message));
  };

  const signupForm = () => {
    return (
      <div className="container-fluid my-5">
        <div className="row">
          <div
            className="col-md-6 offset-sm-3 text-left p-2"
            style={{ backgroundColor: "black", color: "yellowgreen" }}
          >
            <form className="px-4" name="sign">
              <div className="form-group mt-3">
                <label>
                  Name <span className="text-danger">*</span>
                </label>
                <input
                  onChange={handleChange("name")}
                  className="form-control"
                  type="text"
                  value={name}
                  autoCapitalize="words"
                  autoFocus
                  name="Name"
                />
              </div>

              <div className="form-group">
                <label>
                  Email <span className="text-danger">*</span>
                </label>
                <input
                  onChange={handleChange("email")}
                  className="form-control"
                  type="email"
                  name="Email"
                  value={email}
                />
              </div>
              <div className="form-group">
                <label>Phone No.</label>
                <input
                  onChange={handleChange("phone")}
                  className="form-control"
                  type="phone"
                  value={phone}
                  name="Phone"
                />
              </div>
              <div className="row">
                {inputFields.map((inputField, index) => (
                  <Fragment key={`${index}`}>
                    <div className="form-group col-md-4">
                      <label htmlFor="skills">
                        Skill <span className="text-danger">*</span>
                        <span
                          className="text-danger"
                          type="button"
                          onClick={() => handleRemoveFields(index)}
                          style={{
                            display: index === 0 ? "none" : "",
                            fontSize: "30px",
                            marginLeft: "13px",
                          }}
                        >
                          <b>-</b>
                        </span>
                        <span
                          className="text-success"
                          type="button"
                          onClick={() => handleAddFields(index)}
                          style={{
                            fontSize: "30px",
                            marginLeft: "13px",
                          }}
                        >
                          <b>+</b>
                        </span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        value={inputField}
                        onChange={(event) => handleInputChange(index, event)}
                      />
                    </div>
                  </Fragment>
                ))}
              </div>
              <div style={{ textAlign: "right" }}>
                <button
                  onClick={onSubmit}
                  className="btn"
                  type="submit"
                  style={{ backgroundColor: "yellowgreen" }}
                >
                  Submit
                </button>
              </div>

              <br />
            </form>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      <Navbar />
      <div className="container">
        <div className="row text-center">
          <div className="col-12 mt-5">
            <h4 className="font-weight-bold" id="join-us">
              <span style={{ color: "yellowgreen" }}>Join Us</span> Today For
              New <span style={{ color: "yellowgreen" }}>Opportunities</span>
            </h4>
          </div>
        </div>
      </div>
      {onError()}
      {onSuccess()}
      {signupForm()}
      <Footer />
    </div>
  );
};

export default Join;
