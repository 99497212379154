const breakpoints = [3840, 2400, 1080, 640, 384, 256, 128, 96, 64, 48];

const photoLink = (id, w) => `https://lh3.googleusercontent.com/d/${id}=w${w}`;

const getPhotos = (slug) =>
  photosIdsMap[slug.toLowerCase()].map((id) => {
    return {
      src: photoLink(id, 1080),
      width: 150,
      height: 100,
      srcSet: breakpoints.map((breakpoint) => {
        return {
          src: photoLink(id, breakpoint),
          width: breakpoint,
          height: 800,
        };
      }),
    };
  });

export default getPhotos;

export const thumbnailLink = (id) =>
  `https://lh3.googleusercontent.com/d/${id}=w400`;

export const photosIdsMap = {
  "radhe-krishna-villa": [
    "1q_3-1auzDtaIyR2ss5mt1-Jb_FSAuMF7",
    "1hpOAfMIvwdlF-TXvi9xSOuOF0crO_Mp4",
    "17QMwMPqWgZOZBwtxMrvDW63Nl9Bf5c8a",
    "1ZM0ZMLawBR8Urs_bJZulw2Ph-BNcAtRn",
    "1PuNHGqrvdQ52pSkx8nPNusebLfXjKn_B",
    "1mQWt0DMOxwwNNK0vPG4RXpmcABD9H40K",
    "1Ub0xjblmg_q3XZmFaN3LlxJvUzC0qSR1",
    "1HK3fRJtumSvVeI9HojtEGAiZ3jrbXNW2",
    "1XUdS-1XEGLzTYkNjWKbX5DzG48cu5Fyb",
    "1A5O_0zw_uWhRYm7h_fyEVJKZ-tE7BYXf",
    "1bL82zRtjXQlv8bFbbUPcoU0ivAOZrgqX",
    "1EYp4yInqIUMtgJxVD2A_UGbjZBBpb-Fc",
    "1D2zww8VWbttGrj-eTTb1_5Wm3Wl9Mii5",
    "1KfO9nAbDvSfecrKRvWYk9n3ZkEOQRPEY",
    "1KHM_TdpryP8P2ruMueFSnG8rkvSU8WZ6",
    "1K3x5cEY4gBB3zaSpDQMDH3mtqYdm_1da",
    "1ZADO0D541lrzaDyzKujjtEZD57KQ4ptH",
    "1PBsJ9apZeRk8h6X79qFa9V1o7DD6VHDc",
    "1-ztQxppooEbG_5Dvx2P1gI4SBaV4nSwS",
    "17HsrCbA6Vw6hDXej1dqiYdM6JWXODDvn",
    "1PWNUUzRSo5kMk55rhmT_vVEiy9K64A36",
    "1TV_UUhu1nBUXWjVImuD9A2W_FFD39lDu",
    "1XH9VkwsuOGjPNsh_vxhoEIyq09m0yCir",
    "1zT1mKNNpVYJvq0BEj0g7PnUjpLwDZHm6",
    "1aVa--lkCBBnk7weF5dSROfFoOPvG2fUd",
    "1hQeUVuN5qLAvESMMdun-x8OSnZSbGBys",
    "1RjW_0VFzmzddzuwg2_01BgokStRfl5bo",
    "1aalei-7xM_FTjJIgzM0ayc5rKo9ptDMz",
  ],
  "katiyar-house": [
    "13ekjBQtXu9hvQ2Jiz5ig1VuOWrlAY9Je",
    "19B32x7WYE_5dqKG7Wh0gugYM6F1SJA8Y",
    "1jdZKII_hBi4dECKcYGeKjXTaIer3bexY",
    "1N9nk0a7FsWBQrMdgp2cme5LQo4Z5peG8",
    "1ZM0jO5S_pdo2mzvxAi1FSubR5WIUdSe-",
    "1_CXvtuhDdd9H9gajhVz0hYe36-Fpnks5",
    "1lF5GOAcbmTPLtbBHfPfCsxgaQ2vOXY0R",
    "1B1QkEva0nG764Rg3CU_LAAKBnjdWu8XN",
    "1mvGNiXRd200a3dmQrIT1Wwh1h5_iGthx",
    "1DUVGsXUBDFjNyO6TyKJkKItX_e9wQXAS",
    "1ItwlhGyFPsY3Bgv0cyI5Mn0m5BdU5umN",
    "1lxnpE-4SUpSIxiditEuSZWXr-QFwb653",
    "1WkctSK2LVjvV_2Kw1QvL3oKj6iSi5mxU",
    "1b-dgw52CewxCC9s6pSmIQ19Y8G9gLogl",
    "1Lcvdx9xaeY9uWadabYAc04MQrRCbSa2Q",
  ],
  "the-thitwals": [
    "1_n3aKpMv4bkLoFSJ4qAGnGgqcQr1kVpI",
    "14w0oHiIAx4PYxMWL4-2-VGtLPd1Eiy8k",
    "1cs-YmJnZstm6qBSsW8XU0kYcMohwraFd",
    "1OCzN7qaukxMUG7vw27oR0iIx3Y9-iEt5",
    "1b516SpRBwZREwI7MXY9c2XjCnSzbb2FA",
    "1HHD5zIURrRLIuBuSaE6NZwX0yt47948c",
    "1Bp9GvtzqYA-Cb1z9UOqj7-27ZQPcaMo9",
    "1f38o_wMqOAXToa8O3M2NumlUSYQABGpu",
    "1-xG7Knd1LZIcZrHUxKMimJlZRX0Uhb1v",
    "1pQYPAvExEyCdxmPrLpeOx1XdK2Qqi9pb",
    "1YwdvipEKCrJQwe3wkHnnOvABrYi69sdP",
    "1QI7SNNfKoxKsNGPXyamox3Wu11VzdPbX",
    "1QZkJFOm5sN9EoRwT6plaQauY83ReErmj",
    "1OcGDv6Jczce26IJOpvVaxIeT4-RoHVuj",
    "1hjLbHZqMDeldV-hNnQmT2Bg4MlRaCevd",
    "1XWurIbPBjVvb12ne4RNjnpsSFNQNiipW",
    "1yl-c3OibKxFdnukGA61N4ryFVrSMUNJc",
    "1JrMtTtLZiYbTVyFib3bbHeb1YQPoFWQD",
    "1HmKOw-YCvb0q8tmBZHDPZO59KolGs-J5",
    "1fUPLcbR0G713n0LW5G7tVS1s4LPXS7Qy",
    "1RuFwogmLuI_dGqYv7ZP5CYXWaYxC_dfm",
    "1aOXYwa55feNryn2anmroXcIUaOF5MnTA",
    "1j5vBUM6kTj0KeJNHvPMyIBn64TwUCpeS",
    "1v2dHeElnBNcnn-3v2sYTN8q6pT-73w8B",
    "13ehtPNTXnu-9eDf1XiWequ1LnCRZ-5h5",
    "1Ir_NZ4cPh9qUB3zQRwIsDJOMqBjnI6Hv",
    "1nIbRdfLuwXpwB4RYr29Rq7IM0EoFx0oI",
    "1YnJ4JK3sPU9_4RaqS6cgkg2xEz0rEbHn",
    "10x1cd6jPh0pk2bZf-0YV7YIc417rfqfm",
    "1zl7hd1HpNAzQACy5TSvnVBEAexumeTPZ",
    "1J4hkEv1nyeGE4AJ3i-T4c5ilXre8CWAs",
    "1THNTX9DY8L4faH-qIl6Ze9qSmSjBrO3h",
    "1lO6L8xzk0ONZ2UAuiMKszYxHm1mpm4A1",
    "11Fak8XF2kJpbG6m6B0zoLITOqffW-Y4-",
    "1DTYxr_pYnrD5PpX88aeYACO58917nHn9",
    "1seHIXjFDIcDvyjiuiGeQXJRkR0I3vcmg",
    "1QjhNPLPRjXQEST4dUo44yf9_SvUieiIG",
    "10gtJ0hWxotIyk06EMlQzJelNDuS4Co7U",
    "1Yz4pFJUEKoOySpLapSvbBKRGnOngYpJt",
    "1Y5RXPClPnCoEiBI0tslxP5JRe1Ue8Kvf",
    "1pdTVYPtqgUYJXFJ9u_occY1eH1gUK91n",
    "1prcW2SgxrHIjkiqwRBbykaqBMkg0uWwb",
    "1AIOo34SKdjtsrOtofYA24U8sZAtqym--",
    "19AkivogU5jYM6CG_ssw2H7YQYIWamM8f",
  ],
  "jain's-baithak": [
    "1-YpK7hTdvc_Of9JuZNkSkloblq2YX5jI",
    "1XnLLLH6xaxinMBKgpHLVKAMv5T5S_EGX",
    "1FYtWj_cJJfFC6i4rVWG6ydHYeH_IAKEZ",
    "1FC9nEwqTDQGYDwTUWpUhloFOkbFtY9Ep",
    "1LaFKrTkqqSrG0RFyoEmoRt0K2qDnfLvy",
    "1-7EKJG_5Can6CAW3mQpad4SD584QfyKY",
    "1R-3DftHbf4VnsnUVBeN_3EkRIQlRvTJA",
  ],
  "kanchani-jewellers": [
    "1Co2813ouBCdXZBK18YziXZQ-DRSaP2fm",
    "1OAAgpeziSrtZbYJYuvgNlbjyHUhXEDD8",
    "1rDBqaN-EDvTIUVFt3Af1wJjt-LX4SEyC",
    "18o759G-RBp5Ziu8czjJLHP6bPAUlQdOG",
    "1R4TAucrd8Q_NUEEL-yIHsRsUzn1_Di76",
    "1rv84-FeFmJUG4o8JBthUARHYtZe6LSvB",
    "1nxKm6M95Y33BVMuKjzN5uTb8RzwTFSIs",
  ],
  "agarwal-mansion": [
    "1mqG94QcYBR1GGouTTPRHy2BDsl5MBZEM",
    "1A03pFj_TVDTH1yRrzGoj80tcih-WhCQD",
    "1DR9g32qic0zsXonn_TtjXvEgPEDEW9Jj",
    "132PemdrGmSq_qpEaU4kGteYq3t1jVzLn",
    "1UE0y3uxGVJG8xNBWIUuuqTbymWOnDgB3",
    "1zhDvHFAgngGrhP0G9Er5peXSwcBZ2W1u",
    "1vN4alm8MOSueFXLEUjLdyz3hWapiplSQ",
    "19buEDFQPA2UffElJEmyWCBk8wb_Awg9q",
    "1Yobg18Er30kRrnHb6Gsu3QXQ5fDeu6c3",
    "1B2sR3lPNtQ3SrJI_-cjk7cQhtAraE_i7",
    "1T_LFF2C3eJFjZ77P0Mgr4dZxW9MvpSlY",
    "1eeW3bhL7HiWy0zpQhCmjIV2J51C3qEvG",
    "1Gaq7bVsLeMY6t2svLe9GhZFbiudt1h4G",
    "1oTE3x96O7Cr8AnwQLdfpCCnO2ym3SFM8",
    "1d0hNVvqnqRr3Zlakcug-mmmrM6bidcpJ",
    "1YJuUdm--gkCdKaMQAxR-KBBVNXd1uMB0",
    "1Rlh5BmxDbfQw6EDmPPQp4dV0XbBGZRJ3",
    "1Q9P0I1Uq8_BYXH0P4HMFebhGMfYvOLi6",
    "1TnKb5uk-olJ_EkPtAtLST-c4LHOVle3o",
    "1dt4e3_CEk5O2aPL96dxxsO31hxY9tGdD",
    "1FstnPI-r4MWCdaBaqWjHn98T3dr4yUsa",
  ],
  "sharma's-gharana": [
    "1kUvmqF-KGM4Kgqf1zZXBfFa6X8TZztIY",
    "1fKYOoSsdJ0fvWzAWsCcP9IpAtNNFOZYg",
    "1nCFzSp-J3HciEsgFzd0b5thfzppxtRyv",
    "1FiqZqc-vwYis-XOBDrrB2-nli-8QU-PI",
    "13j5A1dNCsj5hUafESqeZmb_UB7OAjzec",
    "11i7sphFcIOyBifOwxznlZL7Q5kVeFoPM",
    "17-F0rzFS86fK4eqsw9hbT6frhJFq2w90",
    "1UAgTbm-qnHcE-MxJXjww2VYICgddUNLT",
    "1zI8SdJiVGd4Bj5IXvNRaVjmMrwbToJKM",
    "1WNXIYQ686_LNILbFBU3v1XrMemIxyxr1",
    "121Ekke3TLEwynJc8_nwb7l4Ij475jkTS",
    "1Iz4P3Y0Gc6eAWXZYLrC8WPPIpdg25ngE",
    "1ij0m5uSOe2KGhhBsH1ZvIk0nnUCf1u0R",
    "1BxWrdz_R7C5GduZ3dVydN_b5pKL4fnSz",
    "1wz8bnLIjCCzqiADfb51d0UT9YI_BV96l",
    "13gBgjbbKLYmu2R4ozmafpiRtzpWoLObU",
    "1FFgUAJkRmB4HXcMmtxIXOZFsnfNH7PUi",
    "1jCYSbufmYGAl2-mSy4EeLhlzrjowYuJg",
    "11gTXrM6T7u-bjxqVQ8ng7LUFnh9KdDYw",
    "1uhkpmUuXzWXvQUDzrAovasZE0p9BRtqo",
    "1qviQz7hE7If8h3gaOOmYYzs0DwRMLayR",
    "1v1XZncC9tNwg5VWwo8QsGAueVPIrzu8T",
  ],
  "sukhdham-villa": [
    "1Zx29QQSBa1uEejnERG8OMBa7yFjaBBu0",
    "1AYb8v3TrtHOLY5X3Vrmh5Ammpj4Njv3I",
    "1xE2L1L87_Of_KGNGEnGWLRzRluqq6GXL",
    "1DkaxhWUzSYil0kqaPNkk01BLf_TfhcvF",
    "1nmsQpyAltes2UpM_rhq-QitjWIAuqCvM",
    "1DFerQPFjhIENasnyPpEwtkae_E0q4i0c",
    "1bIprnYxmxcslEAwu_L44OvLR02cFY1Vf",
    "1i222AymsjN6gvkF-8Yf3RlrZLcd-jp6f",
    "1zpxb5-Wh2V09UDR66OxRpEXCnoAVahbR",
    "1AyoRmjn0R0DWDX0DGASw8aIXn3ExMqrx",
    "1XZ66dijGPPNlS0klW56HkfTlDtgy1i3L",
    "1CcyJUSD_seIYa-nSIgwyFVkHT8KQsZnx",
    "1sjkilBgVoYk6xtVw-6pIwL2V0dM2Qb6-",
    "1nxOECsVevvh45PIWFkRR4RMHGs4mrBxN",
    "1qcfa_Mj0f3kcRXasNtmOaZk6R-45W9nb",
    "1z18-qzf-yJeJzyC4hbwfOirWTKEWFdDH",
    "1MyviZotqcdUNTCgspgr3N8A6QzGUO7g8",
    "1mUwIGepikuuePIaj68DOs8I66RTh9VHL",
    "1PqUjOuLY-Zt38M8lstFRyOsfzF6FNvv7",
    "1WWUEWOjEY3zTVWT-y6t4--yw52S6Y_8r",
    "1JVci0oJrJLzCNPJEs_bm3mcbSvnpVtEH",
    "1twOYJgWR1hVRrhhYa1eHnDGYHMLlF58u",
    "1ToLMk3KnRrMekEII2PTrtZjdoRv24hSl",
    "1L4HR49gQFrFGP_H1oAJIPOEptSa1vPhP",
    "1UxL3SzD1PSvjb-j1FUkSvrUXqUpulLKf",
    "1V4MP5LeK4F3B44znuig2PIkf7dXM0vi3",
    "1Cv_ixzS8wVUCZTkPDdnpC0YhCl5ByVEM",
    "17LbWP2qmhMgFLgLhZ6wTdplnwGXVL1Wx",
    "1xOz6WPEyBtdf0LZErhmtOEZ_TyCc_XpO",
    "1e95-aXXcIQWG7uh366BuVvI_y9YncoHL",
    "1JYHn4k_RCAfJ10RSlS2mgMFnziufS1vn",
    "17-klCuBVmes5FBKl9vS-4jEaaaGIVh_P",
    "1Ere-beC7wIrAyiqKiEAc7cC0DwlgLaDL",
    "1wAmRPxGB4XpK_IHLc7KrNXoHCphrbagS",
    "1uu2jaA34UWJLB-PFnDV9A98IXImItIsO",
    "16AShkS67G0bGx9VEwdq6Hj2SYpQy1yph",
    "1M5PL-nTctW3sxDEbJJnph5Uwg80o_n6q",
    "1M3dd_iO6U_TbAWGsF7cgziQ-vjcUgQQm",
    "1HMrhfKvDSS9i-6WGt8MsVrj6obQLs9X1",
    "1mSmxnMxxm2iOsaRHsK4DKJhc_yPYsV57",
    "18dEqgBoky1G3dlfZd8vPhXC1tVhNGx-w",
    "1Fj61dlQVDXWz2hdzt2Mr_2svuow92bTH",
    "1UjuyArjdlPN3k4rZKt6iwBsXHxGCdkKm",
    "1xZottVmRLj6JlSU67hUgkcy6AyD7euGj",
  ],
  "khicha's-ghar-chennai": [
    "1DcQhYtZgDrtkJLHJLmfCza-Gcsvpuklt",
    "1HdtzQtbW1bnCKIVAnJjs3ns-gKQKdq-0",
    "1rKEKS0_HoD5R5dKPfeYRzZFmI7Qdxdgq",
    "1wh9dk02JTk6Ila07vrljPkskhO3X25O3",
    "1eDiPHeg6hzNgLPfg2Hhex-w1vYz1ykGf",
    "1SbpGuYJTA3-tuYa8d7s-dWIxM8LEipGe",
    "1CE79pWFMXFYJeabLj1IjBgfcRPPGIYwM",
    "1KJo4GagMyWQjdCxGt4sIT4uGcWHhmVZZ",
    "1dg2qb5y0GXXkQWeAtxmLFe96IWGMNjs_",
    "1ExpNNkfU2SRAePn9traW-5qf9Kny8Qsi",
    "1VRQKFXrb9C9N5zzaTu0z5_B6eEOmwUQi",
    "1DsjFBDbgcOsxi3xvVW0yXsZv69PQtKY4",
    "1eFYghjazMOyL69k5ix52RvngniqAp-ma",
    "1938zRjcbFyN0VCOxaFPK9gnWuifTVhvs",
    "13XCnRt76qJ9AVN_GjI41oyMpVpHNXl2N",
    "1E97Lu7Yeslk9ujAe8V9hRJnNC3YrfP_3",
    "1P7aWRkWQp2Yk-0v4xLEpgnHPROKujkXK",
    "1C3pRMEP8CAXMz6dOvulngXgnmHXs20Xp",
    "19dtApRn887hWL40f4XDbJyZ24d-qa4vo",
    "1x9_n92UHYJgTNOSzuYt-p8QHQ4ntFf6T",
    "1ANVKTINXJxsaPFmivqFgRvOf_n3DTMmE",
    "1mTO6XT7ZY6Jp87VT3VRTbGGmKwCx3Yrq",
    "1S_A0RAIgRHN-sTVxZ9Otf-S_H_2LfjpL",
  ],
  "csjm-university": [
    "1TYl4bjD1d6JQOg6URKOyQuuhK1sYEwiw",
    "127Gvz7fSLtqN5htfmw2C0SgaeW96mU2A",
    "1ooJbW3gZsn0nw17dSzODoMixh8NaiRxZ",
    "15tYwONOp-tNURI85G9Q-ahvzsk8m-C1_",
    "1ger7rkSClNQVmykCsPPITQ10femzZ6WG",
    "17MVYf6cUivc9qjzllz1WxhyFhjRxuWT8",
    "1e2gpQb4WmCZHfUwH6cS8MCBu260ZY7a9",
    "1QweVrYCDqPy_slj6WGtJA56MGZuG0buF",
    "1i_W_k9Rz4XZnQh_PZCGrOcK3uwCO6clI",
    "1q_CDcjP5uW9-8TT8ksXpPmnuxyKkZ42R",
    "121uBw2s9SHWzkZkr0qYKyy9376V-ZWkA",
    "1JRATARHzsgqPc24NGJK_43JkYV8E4GVa",
    "1CpUYlg6R29D2-8a-yY282-BwIYN9-21e",
    "1eLzj6BsSBwDWxnutDE8zh3Rn7kjjUtih",
    "1ckm21PZgXaNgHG7uDAcrZSDKCT9G_504",
    "1QnVer5qtR_wwWsN7pRtA--r1Dw0fHd4N",
  ],
  "gupta's-bunglow": [
    "1pQytkIgr3wDrGa3TS9mYdWRXrrRW-zGD",
    "1tRU-xOeCRlGfwEsZm3WC0l5c4-BR_jeh",
    "1LwZSjZCcF8XkfbFYsyPqa1XdY5iqGVi6",
    "1QXc4raYAPQ3t6ekrrfTgdqfCP1kI6n0t",
    "19owThcCgiv6FmYSZXeBwshBxx5MUA8ee",
    "1NMDtjS1Itl6ghKWIXEvupNULhBbnGDJF",
    "1wbYuWoCG9lqvGmpBx1whz5Qbas63MRGx",
    "1zYXUqHv6PfSyr1huNMVYPVO1xr0kb7Ux",
    "1jmbW6b3KcmpNIo3sRr23tI5DzPRCIlKI",
    "1Vv5y0-1lsv0izC-2qCCgdXTJzN3iAyQa",
    "1wXMmOvd7YIO1Qy17p6SnnJ5CrNqa9Sm7",
    "1L1VCB0P6QIyq8nYbbKEndYtKeIpCBLVP",
    "1Hz6Nst51CqDlysiSzrDrX4KtBJDSwylM",
    "1z1Jc9o_VqiU7_MEwAhr_neg0nV1snhUc",
    "1UOuUU2nZJqndsDVmDQEXGRFE-SmhzVQz",
    "1ziLaX5JkmqUunBJ7ZylOk2ujIzLWGBif",
    "1JX4Uo8lg_S3nX5gLtEi_Wex4ANfqB9dT",
    "1QCWkQ2B7SjPS-AupbqauKOMEj9-7BU6b",
    "1U1JWyvX0kOhYJkJkzvexNubdq9H5ltj3",
    "1TePGo3VfunpAcUXuez5vWatEMd8IKN-d",
    "1LIvF9hPupXe2AQRZSYGhVmddwmGWvl1k",
    "1KWIC-I2CwD-cmH5qCnHAAPTDuuFKR-Nd",
    "1F5gasUvGbsHWasB52ENmidvyDtfOnA4w",
    "1S9l59HkXWb6gvnIHRkHWNacna9hRCMqY",
    "1Ni6KzhIudn2_vrKBghRCUW6HfbxgcDBm",
    "1quERS4i1pnZP43SbdkON7AlQ-E9YFykU",
    "1tEpvuYGiWRwfPWXJK8VKLSZ3djeQmM6Z",
    "1NzocrMnf7H_msi4lNSDgiPlg_jzF8_Nh",
    "1ZR0x4YVSRhgoezacoSKWw-hGAolldE7l",
    "1j9lDr6Y6x2bJr54MDwhPv16DSQeKBaPP",
    "1sUwKKlvOu8InwP_2XjLBcVrTuYKLezHX",
    "12jg2XXCmdGVhU2VIyB7fD0vLoijwPobH",
    "1UsD7u2O5ISGLEWCASSxK4moV_vwdcbfy",
  ],
  "apna-ghar-bangalore": [
    "1lkqKKsNrpIF49XG-2trVq5p0a1wxTb0t",
    "12CyHz1YVS2Ins9n_I7FLlIFk--cf7Xtm",
    "1MVeR5DMK1QfhNe5VWrHoqFaWlQt66zFd",
    "15dO65k9KB56Vr4Bz2K9csONNllND_jX2",
    "1ILpr6B1aO3pEvsw0QS4Ix7_L9wMYygqO",
    "19uLJbsLLQeABio14fpC6PEodA4faY4FL",
    "1bm_weiH1RFxsUE8OwQS8Xo6WlyxEbBi5",
    "1Pq0e71h7D3qwIgOiC_SLwkuNcA59xX_h",
    "1uWliJKBtXODjWwG4-ddj9KNpHamxxPhe",
    "1pjrmN1Gzr7X7tybN6u-DYODEh4wKlg9R",
    "16X4o3y1qmu28WBr9A-l3b13vdP5sa2uo",
    "1owT6IGLhDVzmRuZuNegwUdcCpxpMW-Dg",
    "1-ds6iw39c5-ZsE6ED0K-hKy45dB3uIkf",
    "1bSQ5LUR3cvkxmB_Jg_NjskixcOa-Wpn1",
    "1zbMaCTWbWf9zTgzzYZlvfeHPsg4JOU-f",
    "10pzT1-L2ZRf9w9lKOJH1OxZsSfZmafTg",
    "1oVl79Nz_on_o3c9lFYwtQ2Pbtc40i9Bh",
    "1EZguITQskjRuYoLKZLU3TpQcwcX2HgsD",
    "1gRtqa2VdAoyMcfq1QOrzVP2Y0qxjm1yV",
    "15XUtoMqW3sNmKT0GomSshR4_OjRmuwje",
    "1ymc3fqGT_rxeqUTRd78GvuSFGEYT8F7J",
    "1R5reNHyWLTaEJkQQL0hVsZtBCY8Jc5ag",
    "1te6FQZ1CZuiKfyGTF-DL6TMz9OpXpdEJ",
    "1CL05u6lDo9H8v2n7f9juNJ_96MwPpN5K",
    "1lVBMvU7pX9w_Ugzhpduh8VRWlCIoB60G",
  ],
  "thakur's-residence-lucknow": [
    "1AGeVZ65vQk58B6D3xkMimoVh9iHEffhl",
    "1mSF0LIUN231zMTvkaQqZT4cwU6nkCtb0",
    "12omHZdI1eUyCHkObqoMbJTJeanq5OzXq",
    "1GvMIj3IVesZ8Mi9GWO1n47voiKklGr4J",
    "1wce_lY2P5n9XECE0lW1XdSkrxOWRD4Pd",
    "1Kp24M1IPUwzLJx5wcCzAxNymj6j_TOcH",
    "1G3ytQaAFlQ-XOvaVgoqHSAirQA68Hhqw",
    "1ZYzeCr6Aemdw9zlvvhYLqNWJTuAUoiDk",
    "1ljYNYj_cSVBvHLzwG3ITsGrcbiTnCcoS",
    "127apHGTTDJbPqVdVLCYNF4oY8FdZvJDq",
    "1bqPPTSXaVEIpcocOpoSjQekpvWoPM-_y",
    "1QDcCbUsBrFbpuNHcwnBDDocon7xdxs1v",
    "1VIjvNW2b_J48jawEKXhidJ71px1w57Ox",
    "1dT243KPnYd1nmWD2wGt9DJXHlKAjyMlg",
    "1rDOu5aoZaJwfN5KTTb0Aiedr7F24Ww2t",
    "1kGkJIgq6PugZVJaDh-OW2xl8I8BONlJB",
    "16TeIG6JqT5FRP5vUHk48p4XXXV8CliDN",
    "1AQfnAHMTpa8PenZ_Pi1A_ddDm-29B5hN",
    "1r_GadUqcV1u9AfKJxx5rNniiP5054VSF",
    "16X-yb19HL7uSl8x-q_kKe7XzX_-iakaK",
    "1zw4dEZHqQI7vwpIImCoui6rdTnS9QfzT",
    "1oCp5aY1sOagFQCrrCuh792SsaG5PMysF",
    "1_spcJeroTrvqg2F07vLh78f85CSUqpce",
    "16rRcwFb6dN1RtsS9VwUd6AGQ-CfF0cfr",
    "1DY5dvGFkhC7_Y8OFTLO9OxD5gobruLVR",
    "16X7nqXV6Rsv8uB3KVnB7B2xClNmw05-7",
    "1Gl_0wn2vjmLMqD06RtrXYxfdoqTpN3pk",
    "1gejoJ6L7XNrATULYriSZtUyheFygFYDU",
    "1SkDLzu4wpk6KZJRfXy-KIzkyFZAB9BHO",
    "14PYR-Ui9irvZR02ttGG0xDOMoV3_BOJ1",
    "1mpBB0r2k8QSUm9ZmkKHVgbEfQtdhHKvC",
    "11noJl2fT9W2dltr10xxRiOrH98NawRac",
    "1hHodZqPsNNLpB8VWozP9NsQvL-kdtjS8",
    "1zjFu0Z9lThxh3HcQR8Yb_yunwnGoVM3B",
    "1BGQfGPnH76BAZbL0QY54S2sNmdWk2icp",
  ],
};
