import React, { useEffect } from 'react'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import './home.css'

export const About = () => {
    useEffect(() => {
        document.body.style.backgroundColor='#000000'
    })
    return (
        <>
        <Navbar/>
        <div className='container-fluid holder'>
            <div className="container-fluid p-4">
                <div className="row text-center mb-5">
                    <div className="col-12 mt-5">
                        <h1 className="font-weight-bold" >
                            About Us
                        </h1>
                    </div>
                </div>
                <div className="row pt-3">
                    <div className="col-lg-4 text-center">
                        <img src={require('./images/main.jpg')} style={{maxWidth:'70%', boxShadow:'0px 0px 12px 13px rgba(0, 0, 0, 0.15)' }}/>
                    </div>

                    <div className="col-lg-8 mt-5">
                        <p>
                        Bonjour!
                        </p>
                        <p>
                        Aspiring to be a tycoon in the realm of construction, Varun Jain is an innovative and methodical Civil Engineer of the modern era. With a passion to bring a change in stereotype construction methods through technological advancements and innovative ideas, he brought into being LOUNGE LUXURIO. 
                        </p>
                        <p>
                        Quality, Credibility and Innovation are the primary principles on which he bases his ideology. We deliver the designs you dream. Our relationship with clients stands above all. This website arranges for you manifold services which you can avail from any corner of the world.
                        </p>
                        <p>
                        Digitization is the key to accomplishment in today’s world of cut-throat competition. Gone are the days of old-school modus operandi. You demand, we deliver is the motto which inspires us to work for you in need.
                        </p>
                        <p>
                        This platform also provides opportunities to the promising skilled civil engineers, architects and interior designers to connect with us. We hope you enjoy our blogs, avail our services and help us reach greater heights.
                        </p>
                        <p>
                        Gracias!
                        </p>
                    </div>
                </div>
            </div>

            </div>
            <Footer/>
        </>
    )
}
