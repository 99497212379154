import React from "react";
import ReactTypingEffect from "react-typing-effect";
import "./blogCards.css";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import { useEffect } from "react";

const NewTechnology = () => {
  useEffect(() => {
    document.title = "New Technologies | Lounge Luxurio";
  }, []);
  return (
    <div>
      <Navbar />
      <div className=" container-fluid text-center ludwig1" id="name">
        New Technologies
      </div>
      <div className=" container-fluid text-center ludwig2" id="name">
        <div className="row">
          <div className="col-12 text-center">
            <ReactTypingEffect
              text={[
                `Let’s go invent tomorrow instead of worrying about what happened yesterday.`,
              ]}
              typingDelay="100"
              eraseDelay="5000000000"
              cursor=" "
              speed="100"
            ></ReactTypingEffect>
          </div>
        </div>
      </div>
      <div
        className=" container-fluid text-center ludwig"
        style={{ color: "yellowgreen" }}
        id="name"
      >
        <div
          className="row"
          style={{ textAlign: "center", marginTop: "-15px" }}
        >
          <div className="col-12">
            <ReactTypingEffect
              text={[`~Steve Jobs`]}
              eraseDelay="5000000000"
              cursor=" "
              typingDelay="8600"
            ></ReactTypingEffect>
            <br />
          </div>
        </div>
      </div>
      <div className="container" style={{ marginTop: "100px" }}>
        <div className="row">
          <div className="col-12 text-center">
            <h2
              style={{
                borderBottom: " 1px solid yellowgreen",
                paddingBottom: "7px",
                textTransform: "uppercase",
              }}
            >
              3-D Printing
            </h2>
          </div>

          <div className="col-md-10 col-sm-12 offset-md-1 text-center">
            <img
              src={require("./images/new technology/3d printing.jpg")}
              className="banner"
            />
          </div>

          <div className="col-12 text-center details">
            <p>
              The technology has truly been a godsend in the field of
              construction and interior design in particular. With the aid of
              this, one can conveniently craft personalized/made-to-order
              furniture. It is also of countless usage in the creation of
              prototypes at a judicious rate. Combination/Amalgamation of new
              and innovative materials, shapes and structures is another
              splendid feature, which is tedious to implement in tangible
              objects.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-12 text-center" style={{ marginTop: "60px" }}>
            <h2
              style={{
                borderBottom: " 1px solid yellowgreen",
                paddingBottom: "7px",
                textTransform: "uppercase",
              }}
            >
              Smart Homes
            </h2>
          </div>

          <div className="col-md-10 col-sm-12 offset-md-1 text-center">
            <img
              src={require("./images/new technology/smart homes.jpg")}
              className="banner"
            />
          </div>

          <div className="col-12 text-center details">
            <p>
              The idea of ‘Smart homes’ is the need of the present-day age band.
              It involves paramount utilization of a space. The technology
              revolves around the concept of turning living spaces into
              futuristic high-tech homes. Going back to the traditional era, the
              scope for variance in lighting was narrow. However, in contrast to
              it, in the present day we have a wide range of lighting available.
              For instance, users can get access to the control of the lighting
              system and the fan-speed straight from their smart-phones. Also,
              automated timers on bedroom lights are available. It encompasses
              manipulation of light using blinds as well.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-12 text-center" style={{ marginTop: "60px" }}>
            <h2
              style={{
                borderBottom: " 1px solid yellowgreen",
                paddingBottom: "7px",
                textTransform: "uppercase",
              }}
            >
              Digital Furniture
            </h2>
          </div>

          <div className="col-md-10 col-sm-12 offset-md-1 text-center">
            <img
              src={require("./images/new technology/digital furniture.jpg")}
              className="banner"
            />
          </div>

          <div className="col-12 text-center details">
            <p>
              Furniture is an integral part of defining functional sense and
              aesthetically presenting a room. Technology has evolved our
              lifestyle and the way we make use of spaces around us. Let’s take
              up a habitual life example. A couch serves us several purposes
              such as watching television or even sitting and working on a
              laptop. Hence, modern-day furniture should be planned such that it
              can accommodate the upcoming technology. It should contain
              charging ports as well as interactive screens. Change is the law
              of nature and keeping it in mind, one should build furniture as
              per the new standard of living. In recent times, chairs are coming
              up with the options of a tablet-television and an upholstered
              high-back to offer privacy while typing or making phone calls.
              Mobile-phones have scrapped the need for so many things, which
              were earlier used to manipulate the ambience of the house. For
              example, clocks are no longer necessary as one can use his phone
              for the same. Similarly, file-cabinets have become a crucial
              provision in modern furniture.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-12 text-center" style={{ marginTop: "60px" }}>
            <h2
              style={{
                borderBottom: " 1px solid yellowgreen",
                paddingBottom: "7px",
                textTransform: "uppercase",
              }}
            >
              Kinetic Footfall and Roads
            </h2>
          </div>

          <div className="col-md-10 col-sm-12 offset-md-1 text-center">
            <img
              src={require("./images/new technology/kinetic foootfall and roads.jpg")}
              className="banner"
            />
          </div>

          <div className="col-12 text-center details">
            <p>
              It is one of the cutting-edge civil-engineering techniques,
              currently under development. It enables the energy generated by
              the foot-steps to be harnessed from the floors. It can be used on
              a pedestrian footpath in rush-hour traffic areas to produce
              electricity. The generation of electricity takes place through the
              model of piezo-materials and electro-magnetic induction. <br />
              <br /> Kinetic roads are another upcoming technology in the
              construction sector. This concept uses a tyre-like rubber paving
              that converts the kinetic energy produced by moving vehicles into
              electric energy.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-12 text-center" style={{ marginTop: "60px" }}>
            <h2
              style={{
                borderBottom: " 1px solid yellowgreen",
                paddingBottom: "7px",
                textTransform: "uppercase",
              }}
            >
              Cloud Collaboration
            </h2>
          </div>

          <div className="col-md-10 col-sm-12 offset-md-1 text-center">
            <img
              src={require("./images/new technology/cloud collaboration.jpg")}
              className="banner"
            />
          </div>

          <div className="col-12 text-center details">
            <p>
              A cloud collaboration tool known as ‘basestone’ is an embryonic
              technology in the gigantic sphere of civil engineering. It permits
              remote-sharing of data on a construction site in real time.
              Digitization of the drawing review process of a project is what it
              targets. Dedicated to the installation of the whole kit and
              caboodle ranging from steel-beams to light-fittings, the
              technology is a cost-efficient one with old-style paper-based
              review systems.
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default NewTechnology;
