import React, { useState } from "react";
import "./navbar.css";

const Navbar = () => {
  const [modalShowing, setModalShowing] = useState("hide");
  const [modalDisplayCSS, setModalDisplayCSS] = useState("none");
  const [email, setEmail] = useState("");
  const [clicked, setClicked] = useState(false);

  const handleChange = (event) => {
    setEmail(event.target.value);
  };

  const submit = (event) => {
    event.preventDefault();
    console.log(email);
    const scriptURL =
      "https://script.google.com/macros/s/AKfycbwJO0aFC3Y3CYL6kMPAwlSyTxnCMN0nKnE3lLXaTlhNQoT9jAQ/exec";
    const form = document.forms["google-sheet"];

    fetch(scriptURL, { method: "POST", body: new FormData(form) })
      .then((response) => {
        var x = document.getElementById("snackbar");
        x.className = "show";
        setTimeout(function () {
          x.className = x.className.replace("show", "");
        }, 3000);
      })
      .catch((error) => console.error("Error!", error.message));
    handleModalClose();
    setEmail("");
  };

  const handleModalClose = () => {
    setModalShowing("hide");
    setTimeout(() => {
      setModalDisplayCSS("none");
    }, 200);
  };

  return (
    <>
      <div
        className="container-fluid"
        style={{ backgroundColor: "rgb(30,30,30)" }}
      >
        <div className="row">
          <div className="col-12 text-center">
            <span className="capital">D</span>
            <span className="small">ream. </span>
            <span className="capital">D</span>
            <span className="small">emand. </span>{" "}
            <span className="capital">D</span>
            <span className="small">eliver.</span>
          </div>
        </div>
      </div>
      <nav
        className="navbar fixed-top navbar-expand-lg navbar-dark px-3 py-2"
        style={{ position: "sticky", top: 0 }}
      >
        <a className="navbar-brand" href="/">
          <img
            src={require("./logo (2).jpg")}
            alt=""
            style={{ maxHeight: "150px", maxWidth: "150px", marginLeft: 15 }}
          />
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarNavAltMarkup"
          aria-controls="navbarNavAltMarkup"
          aria-expanded="false"
          aria-label="Toggle navigation"
          onClick={() => setClicked(!clicked)}
          style={{ border: "none" }}
        >
          {!clicked ? (
            <span className="navbar-toggler-icon"></span>
          ) : (
            <span className="mr-2">&#10006;</span>
          )}
        </button>
        <div
          className="collapse navbar-collapse justify-content-end"
          id="navbarNavAltMarkup"
        >
          <div className="navbar-nav d-lg-flex justify-content-end">
            <ul className="navbar-nav">
              <li className="nav-item text-center">
                <a className="nav-link" href="/#services">
                  Services
                </a>
              </li>
              <li className="nav-item text-center" id="event">
                <a className="nav-link" href="/projects">
                  Projects
                </a>
              </li>
              <li className="nav-item text-center">
                <a className="nav-link" href="/blogs">
                  Blogs
                </a>
              </li>
              <li className="nav-item text-center">
                <a className="nav-link" href="/join">
                  Join Our Network
                </a>
              </li>
              <li
                className="nav-item text-center"
                onClick={() => {
                  setModalDisplayCSS("block");
                  setTimeout(() => {
                    setModalShowing("show");
                  }, 500);
                }}
              >
                <p className="nav-link mb-0">Newsletter</p>
              </li>
              <li className="nav-item text-center ">
                <a className="nav-link" href="/about">
                  About Us
                </a>
              </li>
              <li className="nav-item text-center">
                <a className="nav-link" href="/#contact">
                  Contact
                </a>
              </li>
            </ul>
          </div>
          <div
            className={`modal fade ${modalShowing}`}
            tabIndex="-1"
            style={{ display: modalDisplayCSS }}
          >
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">Stay in touch!</h5>
                  <button
                    type="button"
                    className="close"
                    onClick={handleModalClose}
                    aria-label="Close"
                  >
                    <span aria-hidden="true" style={{ color: "whitesmoke" }}>
                      &times;
                    </span>
                  </button>
                </div>
                <div className="modal-body">Subscribe to our Newsletter</div>
                <div className="modal-body">
                  <form name="google-sheet" method="post">
                    <input
                      type="email"
                      name="email"
                      placeholder="Enter email"
                      style={{ width: "100%", maxWidth: "500px" }}
                      className="form-control"
                      autoFocus
                      onChange={handleChange}
                      value={email}
                    />
                    <div className="modal-footer">
                      <button
                        type="submit"
                        name="submit"
                        className="btn btn-telegram"
                        style={{
                          backgroundColor: "yellowgreen",
                          color: "black",
                          marginRight: "-15px",
                        }}
                        onClick={submit}
                      >
                        Subscribe
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="snackbar">Subscribed!</div>
      </nav>
    </>
  );
};

export default Navbar;
