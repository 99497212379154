import React, { useEffect, useState } from "react";
import PhotoAlbum from "react-photo-album";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import { useParams } from "react-router-dom";
import Navbar from "../components/Navbar";
import getPhotos, { cacheImages } from "../photos";

const ProjectPage = () => {
  const [index, setIndex] = useState(-1);
  const { slug } = useParams();
  const title = slug.split("-").join(" ");
  const photos = getPhotos(slug);
  // const thumbnails = getThumbnails(slug);

  useEffect(() => {
    // cacheImages(photos);
    // cacheImages(thumbnails);
  }, []);

  return (
    <>
      <Navbar />
      <div className="container text-center pb-5">
        <div
          className="container-fluid text-center ludwig1 mb-5 pb-3"
          id="name"
        >
          {title}
        </div>
        <>
          <PhotoAlbum
            photos={photos}
            layout="rows"
            onClick={({ index }) => setIndex(index)}
          />
          <Lightbox
            slides={photos}
            open={index >= 0}
            index={index}
            close={() => setIndex(-1)}
            plugins={[Fullscreen, Thumbnails]}
          />
        </>
      </div>
    </>
  );
};

export default ProjectPage;
