import React, { useEffect } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import "./home.css";

const Home = () => {
  useEffect(() => {
    if (!sessionStorage.getItem("Hit")) {
      sessionStorage.setItem("Hit", "1");
      const scriptURL =
        "https://script.google.com/macros/s/AKfycbysSuqqzErv0oCkMiDIiwxrxVd7iuaw_HqWWUOLmpcDqgsOq5Q/exec";
      var joinForm = new FormData();
      joinForm.append("Call", "1");
      fetch(scriptURL, { method: "POST", body: joinForm })
        .then((response) => console.log("Hit"))
        .catch((error) => console.error("Error!", error.message));
    }
  });

  return (
    <div>
      <Navbar />
      <div
        id="carouselExampleSlidesOnly"
        className="carousel slide"
        data-ride="carousel"
        data-pause="false"
      >
        <div className="carousel-inner">
          <div className="carousel-item active">
            <img
              src={require("./images/carousel/apartment-chair-clean-contemporary-279719.38794f50.jpg")}
              alt=""
              style={{
                width: "100%",
                maxHeight: "600px",
                objectFit: "cover",
              }}
            />
          </div>
          <div className="carousel-item">
            <img
              src={require("./images/carousel/Minimalist-Interior-Design-defined Cropped.jpg")}
              alt=""
              style={{
                width: "100%",
                maxHeight: "600px",
                objectFit: "cover",
              }}
            />
          </div>
          <div className="carousel-item">
            <img
              src={require("./images/carousel/9a1bca0adfad8e3fd47d8b72f10e95f6 Cropped.jpg")}
              alt=""
              style={{
                width: "100%",
                maxHeight: "600px",
                objectFit: "cover",
              }}
            />
          </div>
        </div>
      </div>

      <div className="container text-center mb-5">
        <div className="speakers-grid grid row py-5">
          <div className="col-12 col-xl-4 col-md-6 mb-5 speaker text-center">
            <a href="/new-technologies" style={{ textDecoration: "none" }}>
              <div className="speakers">
                <div className="speaker-img">
                  <img
                    src={require("./images/cards/new_technology.127b4c05.jpg")}
                    style={{ objectFit: "cover" }}
                    alt="new_technology"
                  />
                </div>
                <div className="speaker-details">
                  <div className="speaker-name">
                    <h4>New Technologies</h4>
                  </div>
                </div>
              </div>
            </a>
          </div>
          <div className="col-12 col-xl-4 col-md-6 mb-5 speaker text-center">
            <a href="/kitchen-design" style={{ textDecoration: "none" }}>
              <div className="speakers">
                <div className="speaker-img">
                  <img
                    src={require("./images/cards/kitchen_design.bbd3ce16.jpg")}
                    style={{ objectFit: "cover" }}
                    alt="kitchen_design"
                  />
                </div>
                <div className="speaker-details">
                  <div className="speaker-name">
                    <h4>Kitchen Designs</h4>
                  </div>
                </div>
              </div>
            </a>
          </div>
          <div className="col-12 col-xl-4 col-md-6 mb-5 speaker text-center">
            <a href="/eco-materials" style={{ textDecoration: "none" }}>
              <div className="speakers">
                <div className="speaker-img">
                  <img
                    src={require("./images/cards/eco_materials.a0d39dd5.jpg")}
                    style={{ objectFit: "cover" }}
                    alt="eco_materials"
                  />
                </div>
                <div className="speaker-details">
                  <div className="speaker-name">
                    <h4>Eco-Material</h4>
                  </div>
                </div>
              </div>
            </a>
          </div>
          <div className="col-12 col-xl-4 col-md-6 mb-5 speaker text-center">
            <a href="/interior-design" style={{ textDecoration: "none" }}>
              <div className="speakers">
                <div className="speaker-img">
                  <img
                    src={require("./images/cards/interior_decor.d0243323.jpg")}
                    style={{ objectFit: "cover" }}
                    alt="interior_decor"
                  />
                </div>
                <div className="speaker-details">
                  <div className="speaker-name">
                    <h4>Interior Decor</h4>
                  </div>
                </div>
              </div>
            </a>
          </div>
          <div className="col-12 col-xl-4 col-md-6 mb-5 speaker text-center">
            <a href="/hitech-furniture" style={{ textDecoration: "none" }}>
              <div className="speakers">
                <div className="speaker-img">
                  <img
                    src={require("./images/cards/furniture_design.3bf4d61d.jpg")}
                    style={{ objectFit: "cover" }}
                    alt="furniture_design"
                  />
                </div>
                <div className="speaker-details">
                  <div className="speaker-name">
                    <h4>Hi-Tech Furniture Designs</h4>
                  </div>
                </div>
              </div>
            </a>
          </div>
          <div className="col-12 col-xl-4 col-md-6 mb-5 speaker text-center">
            <a href="/vastu" style={{ textDecoration: "none" }}>
              <div className="speakers">
                <div className="speaker-img">
                  <img
                    src={require("./images/cards/absolutvision-uCMKx2H1Y38-unsplash.83c5f037.jpg")}
                    style={{ objectFit: "cover" }}
                    alt="absolutvision"
                  />
                </div>
                <div className="speaker-details">
                  <div className="speaker-name">
                    <h4>Residential and Commercial Vastu</h4>
                  </div>
                </div>
              </div>
            </a>
          </div>
          <div className="col-12 col-xl-4 offset-xl-4 offset-md-3 col-md-6  mb-5 speaker text-center">
            <div className="speakers">
              <a href="/queries">
                <div className="speaker-img">
                  <img
                    src={require("./images/cards/queries.jpg")}
                    style={{ objectFit: "cover" }}
                    alt="queries"
                  />
                </div>
              </a>
              <div className="speaker-details">
                <div className="speaker-name">
                  <h4>Queries</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row" id="join-us">
          <div className="col-12 mt-5 mb-1">
            <br />
            &nbsp;
          </div>
        </div>
        <div className="row">
          <div className="col-12 mt-5">
            <h1 className="font-weight-bold">
              Join Our<span style={{ color: "yellowgreen" }}> Network</span>
            </h1>
          </div>
        </div>
        <div className="row">
          <div className="col-12 mt-5">
            <h4 className="font-weight-bold">
              A <span style={{ color: "yellowgreen" }}>Civil Engineer</span> ?
            </h4>
          </div>
        </div>
        <div className="row">
          <div className="col-12 mt-3">
            <h4 className="font-weight-bold">
              An <span style={{ color: "yellowgreen" }}>Architect</span> ?
            </h4>
          </div>
        </div>
        <div className="row">
          <div className="col-12 mt-3">
            <h4 className="font-weight-bold">
              An{" "}
              <span style={{ color: "yellowgreen" }}>Interior Designer </span>?
            </h4>
          </div>
        </div>
        <div className="row">
          <div className="col-12 mt-3">
            <h4 className="font-weight-bold">
              A <span style={{ color: "yellowgreen" }}>Blogger </span>?
            </h4>
          </div>
        </div>
        <div className="row">
          <div className="col-12 mt-3">
            <h4 className="font-weight-bold">
              Looking for opportunities to work on projects{" "}
              <span style={{ color: "yellowgreen" }}>?</span>
            </h4>
          </div>
        </div>
        <div className="row">
          <div className="col-12 mt-5 mb-5 ">
            <a href="/join" style={{ textDecoration: "none" }}>
              <button className="btn btn-outline-light" id="cc">
                <h5>Join Our Network Now</h5>
              </button>
            </a>
          </div>
        </div>
        <div className="row" id="services">
          <div className="col-12 mt-5 mb-1">
            <br />
            &nbsp;
          </div>
        </div>
        <div className="row">
          <div className="col-12 mt-5">
            <h1 className="font-weight-bold">
              Our <span style={{ color: "yellowgreen" }}>Services</span>
            </h1>
          </div>
        </div>

        <div className="row text-left service-list">
          <div className="col-md-5">
            <img
              src={require("./images/services/2d.png")}
              className="service-img"
              style={{ marginLeft: "5px" }}
              alt=""
            />
            <h4 className="service-head">
              <b> 2-D Design Planning & Consultation</b>
              <br />
            </h4>
            <p style={{ textAlign: "right", color: "yellowgreen" }}>
              ____________
            </p>
            <p style={{ marginLeft: "60px" }}>
              <span style={{ color: "yellowgreen" }}>•</span> The complete 2-D
              Plan, Elevations and other required Drawings
              <br />
              <span style={{ color: "yellowgreen" }}>•</span> The proper and
              accurate dimensions and requirements must be provided to us in
              order to proceed
            </p>
          </div>
          <div className="offset-md-1 col-md-5">
            <img
              src={require("./images/services/3d.png")}
              className="service-img"
              alt=""
            />
            <h4 className="service-head">
              <b> 3-D Visualisation & Consultation</b>
              <br />
            </h4>
            <p style={{ textAlign: "right", color: "yellowgreen" }}>
              ____________
            </p>
            <p style={{ marginLeft: "60px" }}>
              <span style={{ color: "yellowgreen" }}>•</span> Plans, Elevations
              and other Drawings in 2-D format <br />
              <span style={{ color: "yellowgreen" }}>•</span> The final 3-D
              Structure, along with the materials used
              <br />
              <span style={{ color: "yellowgreen" }}>•</span> All the Views
              (Front, Rear, Side, Top & Orthographic)
              <br />
              <span style={{ color: "yellowgreen" }}>•</span> Complete realistic
              Photo Renders
            </p>
          </div>
          <div className="col-md-5">
            <img
              src={require("./images/services/analysis.png")}
              className="service-img"
              style={{ marginLeft: "-29px", padding: "10px" }}
              alt=""
            />
            <h4 className="service-head">
              <b> Structural Analysis</b>
              <br />
            </h4>
            <p style={{ textAlign: "right", color: "yellowgreen" }}>
              ____________
            </p>
            <p style={{ marginLeft: "60px" }}>
              <span style={{ color: "yellowgreen" }}>•</span> A
              Software-generated detailed Report of the Structural Analysis of
              the building or structure <br />{" "}
              <span style={{ color: "yellowgreen" }}>•</span> The Beam-Column
              Plan <br /> <span style={{ color: "yellowgreen" }}>•</span> A 3-D
              View of the Beam-Column Structure <br />{" "}
              <span style={{ color: "yellowgreen" }}>•</span> A
              Software-generated Design Report
            </p>
          </div>
          <div className="offset-md-1 col-md-5">
            <img
              src={require("./images/services/estimate.png")}
              className="service-img"
              style={{
                marginLeft: "-25px",
                padding: "15px",
              }}
              alt=""
            />
            <h4 className="service-head">
              <b> Estimation</b>
              <br />
            </h4>
            <p style={{ textAlign: "right", color: "yellowgreen" }}>
              ____________
            </p>
            <p style={{ marginLeft: "60px" }}>
              <span style={{ color: "yellowgreen" }}>•</span> A complete
              detailed Estimation of any new structure to be built <br />
              <span style={{ color: "yellowgreen" }}>•</span> An Estimate of
              re-modelling/renovation of any building or a room <br />
              <span style={{ color: "yellowgreen" }}>•</span> Material Estimate{" "}
              <br />
              <span style={{ color: "yellowgreen" }}>•</span> Labour Estimate
            </p>
          </div>
          <div className="col-md-5">
            <img
              src={require("./images/services/interior.png")}
              className="service-img"
              style={{ marginLeft: "-9px", padding: "12px" }}
              alt=""
            />
            <h4 className="service-head">
              <b> Interior Decoration & Design</b>
              <br />
            </h4>
            <p style={{ textAlign: "right", color: "yellowgreen" }}>
              ____________
            </p>
            <p style={{ marginLeft: "60px" }}>
              <span style={{ color: "yellowgreen" }}>•</span> Interior Planning
              of any house or a room as well <br />
              <span style={{ color: "yellowgreen" }}>•</span>
              Kitchen Design <br />
              <span style={{ color: "yellowgreen" }}>•</span> All sorts of
              Furniture Designs <br />
              <span style={{ color: "yellowgreen" }}>•</span>
              Complete Interior Consultation
            </p>
          </div>
          <div className="offset-md-1 col-md-5">
            <img
              src={require("./images/services/site.png")}
              className="service-img"
              style={{
                marginLeft: "-30px",
                padding: "25px",
                paddingTop: "-8px",
              }}
              alt=""
            />
            <h4 className="service-head">
              <b>Site Visits</b>
              <br />
            </h4>
            <p style={{ textAlign: "right", color: "yellowgreen" }}>
              ____________
            </p>
            <p style={{ marginLeft: "60px" }}>
              <span style={{ color: "yellowgreen" }}>•</span> Site-visits can be
              made by us provided that the area is suitable/possible for us to
              visit. <br />
              <span style={{ color: "yellowgreen" }}>•</span> Complete opinion
              on the client’s requirements
            </p>
          </div>
          <div className="offset-md-3 col-md-5">
            <img
              src={require("./images/services/vastu.png")}
              className="service-img"
              style={{
                marginLeft: "-30px",
                padding: "0px",
              }}
              alt=""
            />
            <h4 className="service-head">
              <b>Vastu Tips</b>
              <br />
            </h4>
            <p style={{ textAlign: "right", color: "yellowgreen" }}>
              ____________
            </p>
            <p style={{ marginLeft: "60px" }}>
              <span style={{ color: "yellowgreen" }}>•</span> Planning of homes
              according to Vastu norms <br />
              <span style={{ color: "yellowgreen" }}>•</span> Re-modelling as
              per Vastu norms <br />
              <span style={{ color: "yellowgreen" }}>•</span> Practical
              Solutions to any Vastu-related problem
            </p>
          </div>
        </div>
      </div>
      <div className="container pb-3 ">
        <div className="row text-center ">
          <div className="col-12 mt-3 mb-5">
            <h4 className="font-weight-bold">
              Want to avail{" "}
              <span style={{ color: "yellowgreen" }}>Our Services</span> ?
            </h4>
            <h5 className="font-weight-bold mt-3">
              Email us at:{" "}
              <a
                href="mailto:loungeluxurio@gmail.com"
                target="_blank"
                rel="noopener noreferrer"
                className="text-white"
              >
                <span style={{ color: "yellowgreen" }}>
                  loungeluxurio@gmail.com
                </span>{" "}
              </a>
            </h5>
            <h5 className="font-weight-bold mt-2">
              Or Fill out the{" "}
              <a href="/queries" className="text-white">
                <span style={{ color: "yellowgreen" }}>Queries Form</span>
              </a>
            </h5>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Home;
