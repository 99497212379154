import React from "react";
import ReactTypingEffect from "react-typing-effect";
import "./blogCards.css";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import { useEffect } from "react";

const KitchenDesign = () => {
  useEffect(() => {
    document.title = "Kitchen Design | Lounge Luxurio ";
  }, []);
  return (
    <div>
      <Navbar />
      <div className=" container-fluid text-center ludwig1" id="name">
        Kitchen Design
      </div>
      <div className=" container-fluid text-center ludwig2" id="name">
        <div className="row">
          <div className="col-12 text-center">
            <ReactTypingEffect
              text={[
                `In the childhood memories of every good cook, there’s a large kitchen, a warm stove, a simmering pot and a mom.`,
              ]}
              typingDelay="100"
              eraseDelay="5000000000"
              cursor=" "
              speed="100"
            ></ReactTypingEffect>
          </div>
        </div>
      </div>
      <div
        className=" container-fluid text-center ludwig"
        style={{ color: "yellowgreen" }}
        id="name"
      >
        <div
          className="row"
          style={{ textAlign: "center", marginTop: "-15px" }}
        >
          <div className="col-12">
            <ReactTypingEffect
              text={[`~Barbara Costikyan`]}
              eraseDelay="5000000000"
              cursor=" "
              typingDelay="12500"
            ></ReactTypingEffect>
            <br />
          </div>
        </div>
      </div>
      <div className="container" style={{ marginTop: "100px" }}>
        <div className="row">
          <div className="col-12 text-center">
            <h2
              style={{
                borderBottom: " 1px solid yellowgreen",
                paddingBottom: "7px",
                textTransform: "uppercase",
              }}
            >
              Conventional Kitchen Work Areas
            </h2>
          </div>

          <div className="col-md-10 col-sm-12 offset-md-1 text-center">
            <img
              src={require("./images/kitchen/apartment-blinds-cabinets-chairs-349749.jpg")}
              className="banner"
            />
          </div>

          <div className="col-12 text-center details">
            <p>
              <span style={{ color: "yellowgreen" }}>1)</span> Primary Sink
              center <br />
              <span style={{ color: "yellowgreen" }}>2)</span> Primary
              Refrigerator center <br />
              <span style={{ color: "yellowgreen" }}>3)</span> Cooktop/Range
              center
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-12 text-center" style={{ marginTop: "60px" }}>
            <h2
              style={{
                borderBottom: " 1px solid yellowgreen",
                paddingBottom: "7px",
                textTransform: "uppercase",
              }}
            >
              Fixtures &amp; Appliances
            </h2>
          </div>

          <div className="col-md-10 col-sm-12 offset-md-1 text-center">
            <img
              src={require("./images/kitchen/pexels-photo-2724748.jpg")}
              className="banner"
            />
          </div>

          <div className="col-12 text-center details">
            <p>
              <span style={{ color: "yellowgreen" }}>1)</span> Sinks <br />
              <span style={{ color: "yellowgreen" }}>2)</span> Fallcets
              <br />
              <span style={{ color: "yellowgreen" }}>3)</span> Garbage Disposers{" "}
              <br />
              <span style={{ color: "yellowgreen" }}>4)</span> Dishwashers{" "}
              <br />
              <span style={{ color: "yellowgreen" }}>5)</span> Ranges <br />
              <span style={{ color: "yellowgreen" }}>6)</span> Cooktops <br />
              <span style={{ color: "yellowgreen" }}>7)</span> Wall Ovens <br />
              <span style={{ color: "yellowgreen" }}>8)</span> Microwave Ovens{" "}
              <br />
              <span style={{ color: "yellowgreen" }}>9)</span> Refrigerators
              <br />
              <span style={{ color: "yellowgreen" }}>10)</span> Trash Compactors{" "}
              <br />
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-12 text-center" style={{ marginTop: "60px" }}>
            <h2
              style={{
                borderBottom: " 1px solid yellowgreen",
                paddingBottom: "7px",
                textTransform: "uppercase",
              }}
            >
              Types Of Kitchens
            </h2>
          </div>

          <div className="col-md-10 col-sm-12 offset-md-1 text-center">
            <img
              src={require("./images/kitchen/architecture-cabinets-chairs-contemporary-279648 (1).jpg")}
              className="banner"
            />
          </div>

          <div className="col-12 text-left details">
            <p>
              <span style={{ color: "yellowgreen" }}> 1) I-Kitchen </span>– It
              is chiefly a one-cook kitchen, involving the cook unaided to carry
              out a lot of tasks. <br />
              <br />{" "}
              <span style={{ color: "yellowgreen" }}>
                2) Double I-Kitchen/Pullman Kitchen{" "}
              </span>
              – It is two-cook kitchen, providing room for others to gather and
              contribute to the meal preparation as well.
              <br /> <br />
              <span style={{ color: "yellowgreen" }}>
                {" "}
                3) The “I with island” Kitchen{" "}
              </span>
              – Being a two-cook kitchen, there is provision for a reasonable
              counter and cabinet storage, thus packing the kitchen into a
              relatively small expanse with mostly effectual double triangles.
              <br /> <br />
              <span style={{ color: "yellowgreen" }}>
                {" "}
                4) The “I with double island” Kitchen{" "}
              </span>
              – It’s a two-cook kitchen with the second island, serving as a
              snack preparation area for a third cook as well. It serves as a
              very large and capacious kitchen. <br />
              <br />
              <span style={{ color: "yellowgreen" }}> 5) The “L” Kitchen </span>
              – It is a one-cook kitchen with a comparatively large work
              triangle. <br />
              <br />
              <span style={{ color: "yellowgreen" }}>
                {" "}
                6) The “L with island” Kitchen
              </span>{" "}
              – This type of kitchen accommodates two cooks with two
              approximately equally-efficient triangles. <br />
              <br />
              <span style={{ color: "yellowgreen" }}>
                {" "}
                7) The “L with double island” Kitchen
              </span>{" "}
              – It is voluminous enough to allow two cooks and multiple
              coworkers. It can be a social center with visitors/family eating,
              drinking and helping. <br />
              <br />
              <span style={{ color: "yellowgreen" }}>
                {" "}
                8) The “J” Kitchen
              </span>{" "}
              – It is a humbly sized one-cook kitchen, which includes an eat-in
              kitchen table moreover. <br />
              <br />
              <span style={{ color: "yellowgreen" }}>
                {" "}
                9) The “J with island” Kitchen
              </span>{" "}
              – This form of kitchen is larger than the average two- cook
              kitchen. The length requisite for the kitchen relates directly to
              the size of the island, which in turn, fits in a cooktop that
              better serves two cooks. <br />
              <br />
              <span style={{ color: "yellowgreen" }}>
                {" "}
                10) The “U” Kitchen
              </span>{" "}
              – It is a small yet well-organized kitchen for a single cook. It
              makes available plentiful wall &amp; base cabinet storage and
              counter frontage in a rather small square footage. <br />
              <br />
              <span style={{ color: "yellowgreen" }}>
                {" "}
                11) The “broken U” Kitchen
              </span>{" "}
              – It has a small and effectual layout. With an additional
              secondary sink, it can well function as a two-cook kitchen. Though
              in a small footprint, there is room for plenteous wall &amp; base
              cabinet storage and counter footage. <br />
              <br />
              <span style={{ color: "yellowgreen" }}>
                {" "}
                12) The “U with island’ Kitchen
              </span>{" "}
              – It accommodates two cooks with an imbalance in the work
              triangles. It provides plentiful wall/base cabinet storage and the
              counter frontage is provided in portions by the island. <br />
              <br />
              <span style={{ color: "yellowgreen" }}>
                {" "}
                13) The “G” Kitchen
              </span>{" "}
              – It is a medium-sized kitchen which accommodates two cooks and
              provides plentiful wall/base cabinet storage and counter footage.
              <br /> <br />
              <span style={{ color: "yellowgreen" }}>
                {" "}
                14) The “broken G” Kitchen
              </span>{" "}
              – It is a medium-sized kitchen which accommodates two cooks, and
              has adequate base/wall cabinet storage and counter footage.
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default KitchenDesign;
