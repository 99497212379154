import React from "react";
import { useEffect } from "react";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import "./home.css";

const Blogs = () => {
  useEffect(() => {
    document.title = "Blogs | Lounge Luxurio";
  });
  return (
    <>
      <Navbar />
      <div
        className="modal fade bd-example-modal-lg"
        tabindex="-1"
        role="dialog"
        aria-labelledby="myLargeModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div class="modal-header">
            <div className="container">
              <div className="row">
                <div className="col-12 text-center">
                  <h3
                    class="modal-title"
                    id="exampleModalLongTitle"
                    style={{ color: "yellowgreen" }}
                  >
                    Having A Life
                  </h3>
                </div>
              </div>
            </div>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true" className="text-white">
                &times;
              </span>
            </button>
          </div>
          <div className="modal-content p-4 ">
            <i>
              {" "}
              ‘Successful engineers must learn how to integrate a busy career
              with community, family, and self. The balance among these elements
              is essential for having a life’
              <br />{" "}
              <div style={{ textAlign: "center", marginBottom: -20 }}>
                {" "}
                - Maya Angelou
              </div>
            </i>{" "}
            <br />
            <br />
            <div style={{ marginBottom: -15 }}>
              Time is the most valuable investment, both in our career and in
              our happiness. It is so precious that we should treat it the way
              it deserves to be. <br />
              <br />
            </div>
            <div style={{ marginBottom: -15 }}>
              So what does ‘HAVING A LIFE’ really mean? Most people say – “Doing
              what I love to do”, “Chilling around”, “Having a meaningful
              relationship”, “Being healthy”, “Being famous”, and what not. Life
              is much more than the tiny particles that trigger our mind and
              body. Life is about laughing, smiling, crying, sharing moments,
              enjoying a variety of meals and leaving the beauty of nature.{" "}
              <br />
              <br />
            </div>
            <div style={{ marginBottom: -15 }}>
              Establishing real life balance with mind, body and spirit will
              play an integral role in achieving a happy life. <br />
              <br />
            </div>
            <div style={{ marginBottom: -15 }}>
              <b>Believe it or not, we sell ourselves more than we think.</b>
              <br />
              <br />
            </div>
            <div style={{ marginBottom: -15 }}>
              <span style={{ color: "yellowgreen" }}>
                {" "}
                <i></i>
              </span>{" "}
              To enter a college, we sell ourselves on applications to prove our
              worth over other applicants. <br />
              <span style={{ color: "yellowgreen" }}>
                <i></i>
              </span>{" "}
              To secure employment, we demonstrate how we can be an asset to the
              organization.
              <br />
              <span style={{ color: "yellowgreen" }}>
                {" "}
                <i></i>
              </span>{" "}
              To become friends, we do not try to be ourselves; rather, we
              constantly try to prove that we are interesting.
              <br />
              <br />
            </div>
            <div style={{ marginBottom: -15 }}>
              This leads to STRESS.
              <br />
              <br />
            </div>
            <div style={{ marginBottom: -15 }}>
              Stress plays an important role in our health and delight. Trust me
              when I say, “the more the stress, the lesser will be the
              efficiency of work and the more will be the complications.”
              <br />
              <br />
            </div>
            <i>
              <div style={{ marginBottom: -15 }}>
                Keys to reduce Stress?
                <br />
                <br />
              </div>
              <div style={{ marginBottom: -15 }}>
                <span style={{ color: "yellowgreen" }}></span> Mindful
                Relaxation
                <br />
                <span style={{ color: "yellowgreen" }}></span> Eat well
                <br />
                <span style={{ color: "yellowgreen" }}></span> Sleep Well
                <br />
                <span style={{ color: "yellowgreen" }}></span> Exercise Well
                <br />
                <span style={{ color: "yellowgreen" }}></span> Personalize your
                fun-time
                <br />
                <span style={{ color: "yellowgreen" }}></span> Keep laughing
                and don’t be serious
                <br />
                <br />
              </div>
            </i>
            <div style={{ textAlign: "center" }}>
              Last but not the least – <br />
              <span style={{ color: "yellowgreen" }}>“BE YOU”</span>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade bd-example-modal-lg-2"
        tabindex="-1"
        role="dialog"
        aria-labelledby="myLargeModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div class="modal-header">
            <div className="container">
              <div className="row">
                <div className="col-12 text-center">
                  <h3
                    class="modal-title"
                    id="exampleModalLongTitle"
                    style={{ color: "yellowgreen" }}
                  >
                    How green buildings can change the future?!
                  </h3>
                </div>
              </div>
            </div>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true" className="text-white">
                &times;
              </span>
            </button>
          </div>
          <div className="modal-content p-4 ">
            <p>
              <b>Past:</b>
            </p>
            <p>
              Since the invention of the steam engine along with other
              incredible science feats, there has been an industrial or
              technological revolution that has been responsible for the upward
              growth of humanity; the more humanity grew the lesser it cared for
              nature, eventually leading to several environmental adversities,
              pollution problems, and climatic changes. The effect of these
              issues is evident from the temperature rise of the Earth due to
              increased carbon dioxide and carbon mono-oxide, the greenhouse
              effect due to the accumulation of greenhouse gases, and acid rain
              due to excess sulphur in the air.
            </p>
            <p>
              <b>Present:</b>
            </p>
            <p>
              In the garb of development, we continuously upgraded humanity and
              degraded the environment by using all the non-renewable resources
              present on this planet to such an extent that we might run out of
              gas, quite literally in the coming few decades, and leave this
              planet as a hard place to survive for our future generations.{" "}
            </p>
            <p>
              Although continuous efforts and initiatives are being planned
              globally, the result of these initiatives is exponential.
            </p>
            <p>
              The cement (material used in construction and concrete industry)
              is alone responsible for almost 5% of the world's carbon
              emissions. It is surprising to know that after water, concrete is
              the second most consumed material on this Earth. The construction
              sector, including the constructed houses that you live in, uses
              and consumes a lot of energy, thus making this sector one of the
              most energy-intensive.
            </p>
            <p>
              As the population is showing an increasing trend, the demand for
              dwellings will also be on the rise. Hence, as this figure is
              expected to rise, to address this problem, the construction
              industry is coming up with green building designs that will help
              fight the adversities and probably restrict a huge amount of
              carbon emissions.{" "}
            </p>

            <p>
              <b>Future:</b>
            </p>
            <p>What are green buildings? </p>
            <p>
              The buildings that are designed specifically aimed at reducing the
              negative impact on man and nature health, such buildings are known
              as green buildings. An ideal green building is one that utilizes
              less water and natural resources, maximizes energy utilization
              with a minimal amount of waste generation.
            </p>
            <p>Some must-have features of a green building-</p>
            <p>
              {" "}
              <ol>
                {" "}
                <li>
                  Use of non-toxic and eco-friendly construction materials like
                  bamboo, recycled plastic, Ashcrete, and more{" "}
                </li>
                <li>
                  Use of renewable energy resources like solar photovoltaic
                  systems
                </li>
                <li>
                  Use of water, energy, and other resources judiciously like
                  rainwater harvesting system for cleaning your vehicles and
                  watering the plants
                </li>
                <li>
                  Use of pollution reduction methods like air purifiers, plants
                  that absorb more carbon
                </li>
                <li>
                  Use of waste reduction methods like refuse, reuse, reduce, and
                  recycle
                </li>
                <li>
                  Use of plants to obtain good indoor environmental air quality
                  like planting an aloe vera
                </li>
              </ol>
            </p>
            <p>
              If a building, irrespective of its purpose has all these
              above-mentioned features, then it can be regarded as a 'green
              building' and help mitigate the carbon levels so that it can
              provide a good life for future generations.{" "}
            </p>
          </div>
        </div>
      </div>

      <div className="container text-center">
        <div className="row">
          <div className="col-12 mt-5 mb-5">
            <h1 className="font-weight-bold">
              <span style={{ color: "yellowgreen" }}>Blogs</span>
            </h1>
          </div>
        </div>
        <div className="row mt-2">
          <div
            className="col-lg-4 col-md-6 col-sm-12 mb-3"
            data-toggle="modal"
            data-target=".bd-example-modal-lg"
            style={{ cursor: "pointer" }}
          >
            <img
              src={require("./images/blogs/blog1.jpg")}
              style={{ maxWidth: "100%", height: 230 }}
              alt=""
            />
            <h2 className="mt-3" style={{ color: "yellowgreen" }}>
              {" "}
              Having A Life
            </h2>
            <h6>~Varun Jain</h6>
          </div>
          <div
            className="col-lg-4 col-md-6 col-sm-12 mb-3"
            data-toggle="modal"
            data-target=".bd-example-modal-lg-2"
            style={{ cursor: "pointer" }}
          >
            <img
              src={require("./images/blogs/blog2.jpg")}
              style={{ maxWidth: "100%", height: 230 }}
              alt=""
            />
            <h2 className="mt-3" style={{ color: "yellowgreen" }}>
              {" "}
              How green buildings can change the future?!
            </h2>
            <h6>~Om Shree Srivastava</h6>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Blogs;
