import React from "react";
import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./core/Home";
import NewTechnology from "./core/NewTechnology";
import KitchenDesign from "./core/KitchenDesign";
import Eco from "./core/Eco";
import Interior from "./core/Interior";
import HiTech from "./core/HiTech";
import Vastu from "./core/Vastu";
import Join from "./core/Join";
import Blogs from "./core/Blogs";
import { About } from "./core/About";
import Projects from "./core/Projects";
import Service from "./core/Service";
import ProjectPage from "./core/ProjectPage";

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/new-technologies" exact element={<NewTechnology />} />
          <Route path="/kitchen-design" exact element={<KitchenDesign />} />
          <Route path="/eco-materials" exact element={<Eco />} />
          <Route path="/interior-design" exact element={<Interior />} />
          <Route path="/hitech-furniture" exact element={<HiTech />} />
          <Route path="/vastu" exact element={<Vastu />} />
          <Route path="/join" exact element={<Join />} />
          <Route path="/blogs" exact element={<Blogs />} />
          <Route path="/about" exact element={<About />} />
          <Route path="/projects" exact element={<Projects />} />
          <Route path="/projects/:slug" element={<ProjectPage />} />
          <Route path="/queries" exact element={<Service />} />
          <Route path="/" element={<Home />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
