import React from "react";
import ReactTypingEffect from "react-typing-effect";
import "./blogCards.css";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import { useEffect } from "react";

const Vastu = () => {
  useEffect(() => {
    document.title = "Vastu | Lounge Luxurio";
  }, []);
  return (
    <div>
      <Navbar />
      <div className=" container-fluid text-center ludwig1" id="name">
        Vastu
      </div>
      <div className=" container-fluid text-center ludwig2" id="name">
        <div className="row">
          <div className="col-12 text-center">
            <ReactTypingEffect
              text={[
                `This space will nourish our soul, and when our soul is nourished, all other aspects of life flourish.`,
              ]}
              typingDelay="100"
              eraseDelay="5000000000"
              cursor=" "
              speed="100"
            ></ReactTypingEffect>
          </div>
        </div>
      </div>
      <div
        className=" container-fluid text-center ludwig"
        style={{ color: "yellowgreen" }}
        id="name"
      >
        <div
          className="row"
          style={{ textAlign: "center", marginTop: "-15px" }}
        >
          <div className="col-12">
            <ReactTypingEffect
              text={[`~Michael Borden`]}
              eraseDelay="5000000000"
              cursor=" "
              typingDelay="11800"
            ></ReactTypingEffect>
            <br />
          </div>
        </div>
      </div>
      <div className="container" style={{ marginTop: "100px" }}>
        <div className="row">
          <div className="col-12 text-center">
            <h2
              style={{
                borderBottom: " 1px solid yellowgreen",
                paddingBottom: "7px",
                textTransform: "uppercase",
              }}
            >
              Office
            </h2>
          </div>

          <div className="col-md-10 col-sm-12 offset-md-1 text-center">
            <img
              src={require("./images/vastu/offices.jpg")}
              className="banner"
            />
          </div>

          <div className="col-12 text-left details">
            <p>
              An office holds a very momentous position in one’s life. One works
              here with utmost devotion to earn a living for himself and his
              family. Predominantly constructed with the motive of earning
              adequate revenues and accomplishing all-round success, an office
              is to be planned prudently, as the positioning of every single
              element and the decoration on the whole matters a lot. One often
              wonders why his/her business faces numerous obstacles in the even
              course of operation despite precision in every segment from his
              end. It occurs when the office has not been arranged with regard
              to appropriate Vastu. Organizing stuff, giving due consideration
              to Vastu ensures a steady flow of income, a healthy relationship
              with staff &amp; competitors as well as a positive business
              environment. Upon my word, the very direction in which you sit may
              influence your business in the long run. <br />
              <br /> Here are a few crucial points one should retain in mind
              while constructing offices :- <br />{" "}
              <span style={{ color: "yellowgreen" }}>1)</span>
              An East-facing <span style={{ color: "yellowgreen" }}>
                (E)
              </span>{" "}
              office is always fruitful. <br />{" "}
              <span style={{ color: "yellowgreen" }}>2)</span> Avoid an
              irregular shape of the plot. A Square or Rectangular shaped plot
              is the superlative selection. <br />{" "}
              <span style={{ color: "yellowgreen" }}>3)</span> The
              Water-resources inside the office should be placed in the
              North-east <span style={{ color: "yellowgreen" }}>(NE)</span>{" "}
              direction. <br /> <span style={{ color: "yellowgreen" }}>4)</span>{" "}
              The owner should face the North{" "}
              <span style={{ color: "yellowgreen" }}>(N)</span> or North-east{" "}
              <span style={{ color: "yellowgreen" }}>(NE)</span> direction while
              working and dealing with the clients. <br />{" "}
              <span style={{ color: "yellowgreen" }}>5)</span> The other
              Staff-members must be seated in the North{" "}
              <span style={{ color: "yellowgreen" }}>(N)</span> or East{" "}
              <span style={{ color: "yellowgreen" }}>(E)</span>
              direction. <br /> <span style={{ color: "yellowgreen" }}>
                6)
              </span>{" "}
              Toilets should preferably be located in the West{" "}
              <span style={{ color: "yellowgreen" }}>(W)</span> or North-west
              <span style={{ color: "yellowgreen" }}>(NW)</span> direction.
              South-east <span style={{ color: "yellowgreen" }}>(SE)</span>,
              North-east <span style={{ color: "yellowgreen" }}>(NE)</span>{" "}
              &amp; East <span style={{ color: "yellowgreen" }}>(E)</span>
              placement of toilets should strictly be avoided. <br />{" "}
              <span style={{ color: "yellowgreen" }}>7)</span> The Pantry should
              be situated in the South-east{" "}
              <span style={{ color: "yellowgreen" }}>(SE)</span> direction.{" "}
              <br /> <span style={{ color: "yellowgreen" }}>8)</span> Avoid
              building the Stairs at the center. They should be built in the
              South <span style={{ color: "yellowgreen" }}>(S)</span>,
              South-west <span style={{ color: "yellowgreen" }}>(SW)</span> or
              West <span style={{ color: "yellowgreen" }}>(W)</span> direction.{" "}
              <br /> <span style={{ color: "yellowgreen" }}>9)</span> The
              Reception should be placed in the North-east{" "}
              <span style={{ color: "yellowgreen" }}>(NE)</span> direction.{" "}
              <br /> <span style={{ color: "yellowgreen" }}>10)</span> Ensure
              that the Employees don’t sit under the beam. <br />{" "}
              <span style={{ color: "yellowgreen" }}>11)</span> Bright and
              elegant Colours should be used to paint the walls, as they bring
              about positivity.
              <br />
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-12 text-center" style={{ marginTop: "60px" }}>
            <h2
              style={{
                borderBottom: " 1px solid yellowgreen",
                paddingBottom: "7px",
                textTransform: "uppercase",
              }}
            >
              Bathroom
            </h2>
          </div>

          <div className="col-md-10 col-sm-12 offset-md-1 text-center">
            <img
              src={require("./images/vastu/bathroom.jpg")}
              className="banner"
            />
          </div>

          <div className="col-12 text-left details">
            <p>
              Although bathrooms are the basic units of any house, their
              placement is often overlooked. This however should not be the
              case. Inappropriate construction of bathrooms, so far as Vastu is
              concerned, results in a number of hurdles and problems related to
              health and finance. A wrongly located bathroom gives out negative
              energy. Thus, one must conform to the Vastu norms while building
              bathrooms in order to guard the household. <br />
              <br /> The following facts must be kept in mind while constructing
              a bathroom :- <br />{" "}
              <span style={{ color: "yellowgreen" }}>1)</span> The Bathroom
              should be located in the Eastern{" "}
              <span style={{ color: "yellowgreen" }}>(E)</span> portion of the
              house. <br /> <span style={{ color: "yellowgreen" }}>2)</span> The
              Toilet should be placed in the West{" "}
              <span style={{ color: "yellowgreen" }}>(W)</span> or North-west{" "}
              <span style={{ color: "yellowgreen" }}>(NW)</span> direction.{" "}
              <br /> <span style={{ color: "yellowgreen" }}>3)</span> The
              Water-draining pipes should be in the North-east{" "}
              <span style={{ color: "yellowgreen" }}>(NE)</span> direction.{" "}
              <br /> <span style={{ color: "yellowgreen" }}>4)</span> The Mirror
              should be fixed on the Northern{" "}
              <span style={{ color: "yellowgreen" }}>(N)</span> or Eastern{" "}
              <span style={{ color: "yellowgreen" }}>(E)</span> wall. <br />{" "}
              <span style={{ color: "yellowgreen" }}>5)</span> The Geyser should
              be in the South-eastern{" "}
              <span style={{ color: "yellowgreen" }}>(SE)</span> direction.{" "}
              <br /> <span style={{ color: "yellowgreen" }}>6)</span> Showers
              and Taps should be fitted on the Northern{" "}
              <span style={{ color: "yellowgreen" }}>(N)</span> wall. <br />{" "}
              <span style={{ color: "yellowgreen" }}>7)</span> The Bathtub
              should be kept in the West{" "}
              <span style={{ color: "yellowgreen" }}>(W)</span> direction and
              the Wash-basin in the North-east{" "}
              <span style={{ color: "yellowgreen" }}>(NE)</span> direction.
              <br /> <span style={{ color: "yellowgreen" }}>8)</span> The
              Overhead-tank should be in the South-west{" "}
              <span style={{ color: "yellowgreen" }}>(SW)</span> direction.{" "}
              <br /> <span style={{ color: "yellowgreen" }}>9)</span>
              Ventilators must be mounted in the East{" "}
              <span style={{ color: "yellowgreen" }}>(E)</span> or North{" "}
              <span style={{ color: "yellowgreen" }}>(N)</span>
              direction. <br />{" "}
              <span style={{ color: "yellowgreen" }}>10)</span> Since Sunrays
              kill the bacteria, dirty laundry should be kept in the Western{" "}
              <span style={{ color: "yellowgreen" }}>(W)</span>
              side of the bathroom. <br />{" "}
              <span style={{ color: "yellowgreen" }}>11)</span> Wall-cabinets
              must be built in the South-western{" "}
              <span style={{ color: "yellowgreen" }}>(SW)</span> portion of the
              bathroom. <br /> <span style={{ color: "yellowgreen" }}>12)</span>
              The Washing machine should be placed in the South-eastern{" "}
              <span style={{ color: "yellowgreen" }}>(SE)</span> or
              North-western <span style={{ color: "yellowgreen" }}>(NW)</span>{" "}
              direction.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-12 text-center" style={{ marginTop: "60px" }}>
            <h2
              style={{
                borderBottom: " 1px solid yellowgreen",
                paddingBottom: "7px",
                textTransform: "uppercase",
              }}
            >
              Kitchen
            </h2>
          </div>

          <div className="col-md-10 col-sm-12 offset-md-1 text-center">
            <img
              src={require("./images/vastu/kitchen.jpg")}
              className="banner"
            />
          </div>

          <div className="col-12 text-left details">
            <p>
              Food is not only vital for our survival but it also helps in our
              growth. Hence, the kitchen in any house is almost hallowed and if
              aptly built, one never falls short of food supplies. We often
              close our eyes to the construction of the kitchen. Apart from the
              overall health of the family, a kitchen built as per Vastu norms
              marks various aspects in one’s life. Untimely-weddings,
              child-birth issues, enormous financial losses and being in debts
              are some of the complications one might face in case the kitchen
              is built unsuitably (as per Vastu). Correct configuration ensures
              delicious and nutritive food, and assists in fighting diseases as
              well. Hence, it is crucial that the kitchen be built in compliance
              to Vastu standards. <br />
              <br /> While constructing a kitchen, the following points must be
              kept in mind :- <br />{" "}
              <span style={{ color: "yellowgreen" }}>1)</span> The direction of
              the Kitchen should be – <br />{" "}
              <span style={{ color: "yellowgreen" }}> &ensp; &#8226; </span>{" "}
              South-east <span style={{ color: "yellowgreen" }}>(SE)</span> –
              First position <br />
              <span style={{ color: "yellowgreen" }}>&ensp; &#8226; </span>{" "}
              North-west <span style={{ color: "yellowgreen" }}>(NW)</span> –
              Second position <br />{" "}
              <span style={{ color: "yellowgreen" }}>2)</span> The South-east
              <span style={{ color: "yellowgreen" }}>(SE)</span> direction is
              governed by the element- fire. <br />{" "}
              <span style={{ color: "yellowgreen" }}>3)</span> The Water-sink
              should be located in the North-east{" "}
              <span style={{ color: "yellowgreen" }}>(NE)</span> direction; and
              away from the gas-stove. <br />{" "}
              <span style={{ color: "yellowgreen" }}>4)</span> Large windows
              should be in the East{" "}
              <span style={{ color: "yellowgreen" }}>(E)</span> direction.{" "}
              <br /> <span style={{ color: "yellowgreen" }}>5)</span> Provision
              for water-storage must be given in the North-east{" "}
              <span style={{ color: "yellowgreen" }}>(NE)</span> direction.{" "}
              <br /> <span style={{ color: "yellowgreen" }}>6)</span> If any,
              the Dining table should be placed in the North-west{" "}
              <span style={{ color: "yellowgreen" }}>(NW)</span>
              direction; and not in the center of the kitchen. <br />{" "}
              <span style={{ color: "yellowgreen" }}>7)</span> The Microwave,
              the Oven and the Refrigerator are to be kept in the South-west
              <span style={{ color: "yellowgreen" }}>(SW)</span> direction.{" "}
              <br /> <span style={{ color: "yellowgreen" }}>8)</span> The Slope
              of Water-flow should be South-west{" "}
              <span style={{ color: "yellowgreen" }}>(SW)</span> to North-east{" "}
              <span style={{ color: "yellowgreen" }}>(NE)</span>. <br />{" "}
              <span style={{ color: "yellowgreen" }}>9)</span> The cylinder
              should be kept in the South-east{" "}
              <span style={{ color: "yellowgreen" }}>(SE)</span> direction.{" "}
              <br /> <span style={{ color: "yellowgreen" }}>10)</span> Storage
              &amp; Overhead-almirahs should be built on the Southern{" "}
              <span style={{ color: "yellowgreen" }}>(S)</span> or Western
              <span style={{ color: "yellowgreen" }}>(W)</span> wall. <br />{" "}
              <span style={{ color: "yellowgreen" }}>11)</span> The Door of the
              kitchen should not face the Door of the bathroom.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-12 text-center" style={{ marginTop: "60px" }}>
            <h2
              style={{
                borderBottom: " 1px solid yellowgreen",
                paddingBottom: "7px",
                textTransform: "uppercase",
              }}
            >
              Important Documents
            </h2>
          </div>

          <div className="col-md-10 col-sm-12 offset-md-1 text-center">
            <img
              src={require("./images/vastu/documents.jpg")}
              className="banner"
            />
          </div>

          <div className="col-12 text-center details">
            <p>
              Where to keep your important documents
              <span style={{ color: "yellowgreen" }}> ?</span>
            </p>
          </div>
          <div className="col-12 text-left details">
            <p>
              <span style={{ color: "yellowgreen" }}>
                1) Visa-papers and Passports :-{" "}
              </span>
              <br />{" "}
              <span style={{ color: "yellowgreen" }}>&ensp; &#8226; </span>
              North-west <span style={{ color: "yellowgreen" }}>(NW)</span> is
              the direction of air-elements <br />{" "}
              <span style={{ color: "yellowgreen" }}>&ensp; &#8226; </span>Keep
              these papers in the North-west{" "}
              <span style={{ color: "yellowgreen" }}>(NW)</span> direction of a
              room. <br />
              <br />{" "}
              <span style={{ color: "yellowgreen" }}>
                2) Property Documents :-{" "}
              </span>
              <br />{" "}
              <span style={{ color: "yellowgreen" }}>&ensp; &#8226; </span>Keep
              these documents in the South-west{" "}
              <span style={{ color: "yellowgreen" }}>(SW)</span> direction in
              order to allow the property to grow. <br />{" "}
              <span style={{ color: "yellowgreen" }}>&ensp; &#8226; </span>DO
              NOT KEEP property papers in a file. Keep the open. Keeping these
              documents in a file will prevent the growth of the property.
              <br />
              <br />{" "}
              <span style={{ color: "yellowgreen" }}>
                3) Disputes with tenants, brothers, relatives, property dispute
                documents, etc. :-{" "}
              </span>
              <br />{" "}
              <span style={{ color: "yellowgreen" }}>&ensp; &#8226; </span>DO
              NOT KEEP these documents in the South-east{" "}
              <span style={{ color: "yellowgreen" }}>(SE)</span> direction.{" "}
              <br />{" "}
              <span style={{ color: "yellowgreen" }}>&ensp; &#8226; </span>Keep
              them in a South-west{" "}
              <span style={{ color: "yellowgreen" }}>(SW)</span> direction.{" "}
              <br />{" "}
              <span style={{ color: "yellowgreen" }}>&ensp; &#8226; </span>Put a
              haldi tilak for multi-fold growth and results in your favour.
              <br />
              <br />{" "}
              <span style={{ color: "yellowgreen" }}>
                4) Court-case and all Legal documents :-
              </span>{" "}
              <br />{" "}
              <span style={{ color: "yellowgreen" }}>&ensp; &#8226; </span>Keep
              these documents in the North-east{" "}
              <span style={{ color: "yellowgreen" }}>(NE)</span> direction.{" "}
              <br />{" "}
              <span style={{ color: "yellowgreen" }}>&ensp; &#8226; </span>You
              will start having decisions in your favour in a peaceful way.{" "}
              <br /> <br />{" "}
              <span style={{ color: "yellowgreen" }}>&#10003; </span>
              Keep all documents in a proper way. Do not fold and keep them. Do
              not put a rubber-band on these documents. <br />{" "}
              <span style={{ color: "yellowgreen" }}>&#10003; </span> Have faith
              while doing this and do it with a happy and positive mind.
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Vastu;
